<template>
  <v-container fluid :class="headerWidthClass">
    <v-row class="d-flex justify-center">
      <v-col cols="10" lg="8" class="justify-start d-flex" style="color: #f68b44; margin-top: 40px">
        <h1>UniXcape Voice + Commu</h1>
      </v-col>
    </v-row>
    <v-row class="justify-start d-flex">
      <v-col cols="12" lg="12">
        <v-divider :style="dividerWidth" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6" class="justify-center text-section mt-6">
        <v-row class="d-flex" :class="isLarge ? 'justify-center' : 'justify-center'">
          <v-col cols="10" md="10" lg="10" :class="isSmall ? 'justify-center d-flex' : 'justify-start d-flex'">
            <h2 class="text-start">UniXcape Voice + Commu</h2>
          </v-col>
        </v-row>
        <v-row class="d-flex " :class="isLarge ? 'justify-center' : 'justify-center'">
          <v-col cols="10" md="10" lg="10" class="justify-center d-flex">
            <p class="text-start">
              UniXcape Voice+Commu is a versatile platform that seamlessly
              integrates IP-based communication technologies, offering
              unparalleled flexibility to deploy these technologies in a manner
              that best suits your organization’s pace and needs. Supported on
              iOS (13 and above) and Android (7 and above), this robust
              application combines multiple modes of communication, including
              Voice Call and Video Call, enabling you to make or receive calls
              anytime and anywhere without incurring cellular charges (Internet
              connection required). This feature not only lowers air-time
              minutes but also eliminates roaming charges, making it an
              attractive alternative for maintaining productivity and providing
              exceptional customer service outside a traditional office
              setting.<br /><br />

              This integration of UniXcape Voice and Commu ensures that your
              organization stays connected and productive, with a modern,
              user-friendly interface that responds to the dynamic needs of
              today's mobile workforce. With the added benefits of enhanced
              communication modes and reduced operational costs, UniXcape
              Voice+Commu is the ultimate solution for efficient and effective
              business communication.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mt-16" :cols="12" :sm="6" :md="6" :lg="3" :xl="3" :xxl="3" :class="justifyCommu">
        <div class="d-flex" :class="justifyCommu" :style="isMobile ? 'width:350px; ' : 'width: 500px;'">
          <v-img width="50%" src="../assets/home5.png"></v-img>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6">
        <v-row class="d-flex" :class="justifyCommu">
          <v-col cols="8" class="d-flex" :class="justifyCommu">
            <v-img width="90%" src="../assets/commu+voice solution.png"></v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6" class="text-section">
        <v-row class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start  ml-5'">
          <v-col cols="8" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <h2 class="text-start">Solution Overview</h2>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-start ml-5' : 'justify-center'">
          <v-col cols="10" xl="9" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <p>
              UniXcape Voice + Commu provides an all-encompassing mobility
              solution for next-generation mobile clients on smartphones,
              combining SIP PABX-based VoIP, UniXcape Communications, and Video
              Features into one single application. This integration ensures
              that remote workers can communicate effortlessly, maintaining
              productivity and connectivity regardless of their location.<br /><br />

              The integration of UniXcape Voice and Commu ensures a robust and
              flexible communication platform. UniXcape Communicator enhances
              mobility by combining voice, video, and messaging services into a
              unified application, enabling teams to stay connected and
              productive from anywhere. Users can easily manage communications,
              collaborate, and access corporate contact lists and group members
              pre-defined by system administrators.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="8" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
        <h2 class="feature-title">Features</h2>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col :cols="11" lg="8" class="justify-center d-flex">
        <v-row class="d-flex justify-start" :class="isLarge ? 'mt-8' : 'mt-5'">
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="150px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-phone-plus-outline</v-icon>
                IP-Based Communication
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Supports both voice and video calls over an IP network,
                  reducing traditional cellular costs and avoiding roaming
                  charges.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="150px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-cellphone</v-icon>Mobile Support
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Available on iOS (13+) and Android (7+), ensuring wide
                  compatibility and accessibility for mobile users.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="150px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-application-brackets-outline</v-icon>
                Seamless Integration
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Can be integrated with existing CRM and other business systems
                  to streamline workflows and enhance efficiency.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="150px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-account-group-outline</v-icon>
                Scalability
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Supports up to 10,000 users per system and virtually unlimited
                  users in a network, suitable for businesses of all sizes.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="150px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-human-capacity-decrease</v-icon>Reliability and Redundancy
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Offers a fault-tolerant and redundant system architecture to
                  ensure uninterrupted service.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="150px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-cloud-upload-outline</v-icon>
                Private and Public Cloud Deployment
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Can be deployed in a private cloud from a data center or as a
                  multi-tenant public cloud solution.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="150px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-application-outline</v-icon>Unified Communication
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Integrates multiple communication modes, including voice,
                  video, and messaging, into a single application.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="150px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-account-multiple-plus-outline</v-icon>User-Friendly
                Interface
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Modern, intuitive, and responsive UI that adapts to various
                  devices, enhancing the user experience.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="150px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-package-variant</v-icon>
                Active Development and Support
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Continuously updated with active development, ensuring the
                  latest features and security enhancements.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <h1 style="color: #f0f0f0" class="text-center">
          Connect to see More Information
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <h4 style="color: #f0f0f0">UniXcape Voice + Commu Information PDF File</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <v-icon style="color: #f0f0f0; font-size: 6rem">mdi-information-variant-circle</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <v-btn @click="clickToDownload" outlined color="#f68b44" class="title-case">Download File</v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  computed: {
    dividerWidth() {
      const width = window.innerWidth;
      console.log("width : ", width);
      const fullHd = width >= 1920 && width < 2048;
      const is2K = width >= 2048 && width < 3840;
      const is4K = width >= 3840;

      if (is2K) {
        return "width: 27%";
      }

      if (is4K) {
        return "width: 27%";
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 70%";
        case "sm":
          return "width: 57%";
        case "md":
          return "width: 45%";
        case "lg":
          return "width: 43%";
        case "xl":
          return "width: 36%";
      }
    },
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100px";
        case "sm":
          return "200px";
        case "md":
          return "200px";
        case "lg":
          return "200px";
        case "xl":
          return "200px";
      }
    },
    justifyCommu() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "justify-center ";
        case "sm":
          return "justify-center ";
        case "md":
          return "justify-center ";
        case "lg":
          return "justify-end ";
        case "xl":
          return "justify-end ";
      }
    },
    headerClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "";
        case "sm":
          return "";
        case "md":
          return "";
        case "lg":
          return "";
        case "xl":
          return "";
      }
    },
    isSmall() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    isDesktop() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
          navigator.userAgent
        )
      ) {
        // some code..
        console.log("mobile detected");
        return false;
      } else {
        console.log("desktop detected");
        return true;
      }
    },
    headerWidthClass() {
      if (this.isDesktop) {
        return "xs-width";
      }
      return "";
    },
    isLarge() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
    isMobile() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    clickToDownload() {
      const file = this.path.find((doc) => doc._id === "UXC-Voice+Commu");
      if (file) {
        this.downloadFile(file.documentPath);
      } else {
        console.log("File not found");
      }
    },
    downloadFile(path) {
      const url = `${process.env.BASE_URL}${path}`;
      const filename = path.split("/").pop(); // Extract the filename from the path
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set only the filename in the download attribute
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    pathPdf(data) {
      for (const item of data) {
        const url = item.documentPath;
        const basePath = "/html/";
        const path = url.split(basePath)[1];
        item.documentPath = path;
        console.log("path", path);
      }
    },
    async initialize() {
      const request = "api/documents";

      await axios
        .get(request, {})
        .then((res) => {
          console.log("response : ", res);
          this.path = res.data.rows;
          this.pathPdf(this.path);
          console.log("lol", this.path);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.divider-line {
  background-color: #f68b44;
  margin-right: 1400px;
}

.divider-center {
  background-color: #f68b44;
  margin-left: 300px;
  margin-right: 300px;
}

.title-case {
  text-transform: capitalize;
}

.xs-width {
  width: 120vw;
}
</style>
