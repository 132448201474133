<template>
  <v-container fluid :class="headerWidthClass" class="pa-0">
    <v-window v-model="currentSlide">
      <v-window-item v-for="(slide, index) in slides" :key="`card-${index}`" @click="navigateToProduct(slide.route)">
        <v-card :class="[
          'responsive-card',
          {
            'bg-2k': is2K,
            'bg-4k': is4K,
            'bg-mobile': isMobile,
            'bg-tablet': isTablet,
          },
        ]">
          <v-row class="fill-height" align="center" justify="center">
            <v-col cols="12" md="6" class="d-flex justify-end">
              <div :style="isMobile ? 'width: 100%' : imageWidth" class="pa-5">
                <v-row class="mx-auto">
                  <v-col cols="12" class="d-flex" :class="isTablet ? 'justify-center' : 'justify-start'">
                    <div class="font-weight-black" :class="headerSize" style="color: #f68b44" v-html="slide.title">
                    </div>
                    <v-spacer v-if="showSpacer"></v-spacer>
                    <div :style="imageWidth" v-if="slide.image && isMobile">
                      <v-img :src="require(`@/assets/${slide.image}`)" class="responsive-img"></v-img>
                    </div>
                  </v-col>
                  <v-col cols="12" class="text-start mt-3 justify-start d-flex">
                    <div :style="textSize" class="description-text truncate-banner" style="overflow-wrap: break-word"
                      v-html="slide.description"></div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-start">
              <div :style="imageWidth" v-if="slide.image && !isMobile">
                <v-img :src="require(`@/assets/${slide.image}`)" class="responsive-img"></v-img>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-window-item>
    </v-window>
    <v-card-actions class="justify-center d-flex mt-n12">
      <v-item-group class="text-center" mandatory>
        <v-item v-for="(slide, index) in slides" :key="`btn-${index}`" v-slot="{ toggle }" :value="index">
          <div class="mr-2" style="display: inline-block" icon @click="goToSlide(index)">
            <v-icon size="8" :color="currentSlide === index ? 'orange' : 'gray'">mdi-circle</v-icon>
          </div>
        </v-item>
      </v-item-group>
    </v-card-actions>
    <div class="pt-6 pb-7">
      <v-row v-for="(product, index) in products" :key="index" class="mx-auto d-flex justify-center"
        style="width: 100%">
        <v-col cols="11" lg="8" :class="colClass">
          <v-card :style="isCard" class="justify-center d-flex product-card flex-column"
            @click="navigateToProduct(product.route)" @mouseenter="setHover(product.title)"
            @mouseleave="setHover(null)">
            <v-row no-gutters class="flex-grow-1">
              <v-col cols="12" md="4" class="px-0">
                <v-img width="100%" :src="require(`/src/assets/${product.image}`)"
                  :class="isSmall ? 'product-image-sm' : 'product-image'"></v-img>
              </v-col>
              <v-col cols="12" md="8" class="d-flex flex-column px-0">
                <v-card-title class="product-title" style="word-break: break-word; overflow-wrap: break-word">
                  {{ product.title }}
                </v-card-title>
                <v-card-text class="flex-grow-1" style="text-wrap: wrap">
                  <div v-html="product.description" :style="textSize" class="product-description truncate-text"></div>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click.stop="navigateToProduct(product.route)" class="title-case learn-more-btn mb-3" text
                    :class="{
                      'hover-underline': hoveredProductTitle === product.title,
                    }">
                    Learn More
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      currentSlide: 0,
      onboarding: 0,
      hover: false,
      hoveredProductTitle: null,
      is2K: false,
      is4K: false,
      // isMobile: false,
      // isTablet: false,
      slides: [
        {
          title: "UniXcape<br />Commu",
          description:
            "UniXcape is the communication software solution to enhance customer capability in communication. Based on open standard, UniXcape can be connected to legacy PBX as well as modern Softswitch to add more<br> functionality and efficiency.",
          image: "home1.png",
          route: "/commu",
        },
        {
          title: "UniXcape<br />Voice",
          description:
            "UniXcape Voice is a flexible platform for deploying new IP-based communications technologies at your own pace. It enhances productivity, satisfaction, and customer service, making it an attractive alternative for working outside a traditional office setting.",
          image: "home2.png",
          route: "/voice",
        },
        {
          title: "Omnichannel<br />Contact Center",
          description:
            " Omnichannel Contact Center is a web-based application that supports up to 5,000 extensions and integrates multimedia solutions like voice, email, and live chat. It handles inbound and outbound interactions, single or multi-site operations, and integrates with CRM systems, Fax Server, and IVR Enterprise, providing robust capabilities for mid-to-large contact centers.",
          image: "home3.png",
          route: "/contact-center",
        },
        {
          title: "UniXcape<br />Meet",
          description:
            "UniXcape Meet is a software for collaboration, including communication, appointments, meetings, chats, online file sharing, and task follow-ups. It allows video recording for later viewing and can be accessed via the website or smartphone.",
          image: "home4.png",
          route: "/meet",
        },
        {
          title: "UniXcape<br />Voice + Commu",
          description:
            "UniXcape Voice+Commu is a versatile IP-based communication platform for iOS  and Android. It supports voice and video calls over the internet, reducing cellular and roaming charges, and enhancing productivity and customer service outside the office. With its modern interface and cost savings, it's an ideal solution for efficient business communication.",
          image: "home5.png",
          route: "/voice+commu",
        },
        {
          title: "Customer Relation Management",
          description:
            "CRM is a system for managing and analyzing customer interactions and data throughout the customer lifecycle. Its primary goal is to improve customer relationships, retain customers, and drive business growth. CRM enhances business operations by streamlining processes, optimizing workflows, and improving customer interactions at every touchpoint.",
          image: "home6.png",
          route: "/crm",
        },
      ],
      products: [
        {
          image: "image1.png",
          title: "UniXcape Commu",
          description:
            "Seamlessly integrate and enhance your communication capabilities with UniXcape CRM, utilizing cutting-edge technology and unmatched efficiency to elevate your business operations and customer interactions to new heights.",
          learnmore: "LEARN MORE",
          route: "/commu",
        },
        {
          image: "image2.png",
          title: "UniXcape Voice",
          description:
            "Flexibly deploy IP communications with UniXcape Voice, boosting productivity and enhancing customer service in any location, whether you're in the office or working remotely, ensuring seamless and efficient communication across your entire organization.",
          learnmore: "LEARN MORE",
          route: "/voice",
        },
        {
          image: "image3.png",
          title: "Omnichannel Contact Center",
          description:
            "Integrate voice, email, and chat seamlessly with our robust CRM and IVR support, accommodating up to 5,000 extensions. This comprehensive solution ensures efficient and effective communication, streamlining your operations and enhancing customer interactions across all channels.",
          learnmore: "LEARN MORE",
          route: "/contact-center",
        },
        {
          image: "image4.png",
          title: "UniXcape Meet",
          description:
            "Experience seamless collaboration with UniXcape Meet, offering comprehensive communication tools, virtual meetings, and file sharing capabilities, all easily accessible on both web and smartphone platforms. Enhance your productivity and stay connected wherever you are.",
          learnmore: "LEARN MORE",
          route: "/meet",
        },
        {
          image: "image.png",
          title: "UniXcape Voice + Commu",
          description:
            "Flexibly deploy IP communications with UniXcape Voice, boosting productivity and seamlessly integrating with both legacy PBX systems and modern Softswitch technology. This ensures enhanced efficiency, allowing your organization to adapt to new communication technologies at its own pace while maintaining a high level of operational performance and connectivity.",
          learnmore: "LEARN MORE",
          route: "/voice+commu",
        },
        {
          image: "image6.png",
          title: "CRM (Customer Relation Management)",
          description:
            "Elevate efficiency and customer satisfaction across your organization with CRM, a versatile suite of easy-to-implement solutions designed to streamline processes, optimize workflows, and enhance customer interactions at every touchpoint, ensuring lasting success and growth for your business.<br>",
          learnmore: "LEARN MORE",
          route: "/crm",
        },
      ],
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.name;
    },
    isTablet() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    showSpacer() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    isCard() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return "height: 290px";
        case "lg":
          return "height: 290px";
        case "xl":
          return "height: 290px";
      }
    },
    isSmall() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    headerSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h5";
        case "sm":
          return "text-h3";
        case "md":
          return "text-h3";
        case "lg":
          return "text-h2";
        case "xl":
          return "text-h2";
      }
    },
    textSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size: 12px !important";
        case "sm":
          return "font-size:14px !important";
        case "md":
          return "font-size:16px !important";
        case "lg":
          return "font-size:16px !important";
        case "xl":
          return "font-size:16px !important";
      }
    },
    isDesktop() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
          navigator.userAgent
        )
      ) {
        // some code..
        console.log("mobile detected");
        return false;
      } else {
        console.log("desktop detected");
        return true;
      }
    },
    headerWidthClass() {
      if (this.isDesktop) {
        if (this.$vuetify.breakpoint.xs) {
          return "xs-width";
        }
      }
      return "";
    },
    colClass() {
      if (!this.isDesktop) {
        if (this.$vuetify.breakpoint.xs) {
          return "px-0 pb-0";
        }
      }
      return "";
    },
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 180px";
        case "sm":
          return "width: 400px";
        case "md":
          return "width: 400px";
        case "lg":
          return "width: 600px";
        case "xl":
          return "width: 630px";
      }
    },
    textMarginStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return;
        case "sm":
          return;
        case "md":
          return "marginLeft: 100px";
        case "lg":
          return "marginLeft: 350px";
        case "xl":
          return "marginLeft: 500px";
      }
    },
  },
  created() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      const width = window.innerWidth;
      this.is2K = width >= 2048 && width < 3840;
      this.is4K = width >= 3840;
    },
    setHover(item) {
      this.hoveredProductTitle = item;
    },
    navigateToProduct(route) {
      if (route) {
        this.$router.push({ path: route });
      } else {
        console.log("Route not defined for this product");
        // Optionally, handle products without a route
      }
    },
    goToSlide(index) {
      this.currentSlide = index;
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
  },
  mounted() {
    setInterval(this.nextSlide, 5000);
  },
};
</script>

<style scoped>
.responsive-card {
  background-color: rgba(101, 140, 161, 0.7);
  height: 450px;
}

.bg-2k {
  background-color: rgba(101, 140, 161, 0.8);
}

.bg-4k {
  background-color: rgba(101, 140, 161, 0.9);
}

.bg-mobile {
  background-color: rgba(101, 140, 161, 0.6);
}

.bg-tablet {
  background-color: rgba(101, 140, 161, 0.7);
}

/* .responsive-img {
  width: 100%;
  height: auto;
} */

.description-text {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 550;
  /* font-size: 1rem; */
  line-height: 1.5rem;
  color: #ffffff;
}

/* .responsive-img {
  width: 100%;
  max-width: 500px;
} */

.product-card {
  background: linear-gradient(180deg, #e6a645 0%, #d76f3c 100%);
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.product-image {
  max-width: 100%;
  height: 100%;

  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;

  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.product-image-sm {
  max-width: 100%;
  height: 100%;

  border-top-left-radius: 14px;
  border-top-right-radius: 14px;

  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.product-title {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.25rem;
  color: #ffffff;
  margin-left: 2%;
  margin-right: 2%;
  background-color: transparent;
  box-shadow: none;
}

.product-description {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  /* font-size: 1rem; */
  line-height: 1.5rem;
  color: #ffffff;
  margin-left: 2%;
  margin-right: 2%;
  background-color: transparent;
  box-shadow: none;
}

.learn-more-btn {
  margin-top: 1rem;
  margin-left: 2%;
  margin-right: 2%;
  background-color: transparent;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5rem;
  color: #ff9d4d;
}

.hover-underline:hover {
  text-decoration: underline;
}

/* @media (max-width: 600px) {
  .product-card {
    flex-direction: column;
  }

  .product-image {
    border-radius: 14px 14px 0 0;
  }
} */

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-banner {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.xs-width {
  width: 120vw;
}
</style>
