<template>
  <v-container fluid :class="headerWidthClass">
    <v-row class="d-flex justify-center">
      <v-col cols="10" lg="8" class="justify-start d-flex" style="color: #f68b44; margin-top: 40px">
        <h1>UniXcape Voice</h1>
      </v-col>
    </v-row>
    <v-row class="justify-start d-flex">
      <v-col cols="12" lg="12">
        <v-divider class="custom-height" :style="dividerWidth" color="#f68b44"></v-divider>
      </v-col>
    </v-row>

    <v-row class="justify-center d-flex">
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6" class="justify-center text-section mt-6">
        <v-row class="d-flex" :class="isLarge ? 'justify-end' : 'justify-center'">
          <v-col cols="10" md="10" lg="10" :class="isSmall ? 'justify-center d-flex' : 'justify-start d-flex'">
            <h2 :class="headerClass">UniXcape Voice</h2>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-end' : 'justify-center'">
          <v-col cols="11" md="10" lg="10" class="justify-center d-flex">
            <p class="text-start">
              UniXcape Voice is a platform that gives you the flexibility to
              deploy new and IP-based communications technologies in the way
              that suits you best, and at the ideal pace for your organization.
              It is not surprising, it offers an attractive alternative, and
              believes it. it is a dramatic increase in productivity,
              satisfaction, and provides best-of-breed customer service while
              away from a traditional office setting.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mt-6 text-center" :cols="12" :sm="12" :md="12" :lg="4" :xl="4" :xxl="4" :class="justifyCommu">
        <div class="mt-6 d-flex justify-center">
          <v-img contain max-width="70%" max-height="100%" src="../assets/home2.png"></v-img>
        </div>
      </v-col>
    </v-row>

    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6">
        <v-row class="d-flex" :class="justifyCommu">
          <v-col cols="8" class="d-flex" :class="justifyCommu">
            <v-img width="90%" src="../assets/solution voice.png"></v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="12" :sm="12" :md="12" :lg="5" :xl="5" :xxl="5" class="text-section">
        <v-row class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start  ml-16'">
          <v-col cols="8" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <h2 class="text-start">Solution Overview</h2>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-start ml-16' : 'justify-center'">
          <v-col cols="11" md="10" lg="10" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <p class="text-start">
              UniXcape Voice is a native SIP-based VOIP system supporting up to
              10,000 users per system and unlimited users in a network. It
              offers reliable, redundant hardware with comprehensive business
              features, deployable as a private or public cloud solution. Ideal
              for large enterprises, it supports 1,000 to 100,000 users across
              multiple sites. The UniXcape Branch provides a survivable branch
              office solution with a media processor, firewall, and Session
              Border Controller, reducing deployment, bandwidth, and service
              costs.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="8" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
        <h2 class="text-start" style="color: white">Features</h2>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col :cols="11" lg="8" class="justify-center d-flex">
        <v-row class="d-flex justify-start" :class="isLarge ? 'mt-8' : 'mt-5'">
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="170px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-account-cog-outline</v-icon>
                Call forwarding – all/busy/no answer/follow me
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  MThis feature allows users to set calls to be forwarded to
                  another number if the line is busy, unanswered, or set to
                  follow them wherever they go, ensuring no important call is
                  missed.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="170px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-phone-paused-outline</v-icon>
                Call hold
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Holding a call is a fundamental feature that allows users to
                  manage calls effectively by giving them time to handle
                  something before resuming the conversation.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="170px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-video-outline</v-icon>
                Conference – Meet-Me/Ad Hoc
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Conference calling enables multiple people to join a single
                  call, whether scheduled in advance (Meet-Me) or spontaneously
                  (Ad Hoc), facilitating group discussions.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="170px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-minus-circle-outline</v-icon>
                Do not disturb
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  This feature lets users block all incoming calls temporarily,
                  ensuring they are not interrupted during periods requiring
                  concentration or privacy.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="170px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-music-note-eighth</v-icon>
                Music on hold
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Playing music or messages while a call is on hold keeps the
                  caller informed that the connection is still active and
                  provides a better experience for the caller.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="170px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-account-music-outline</v-icon>
                Do not disturb
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  This feature allows incoming calls to ring on multiple devices
                  simultaneously, such as a mobile phone and office phone,
                  ensuring the user can answer the call regardless of their
                  location.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="170px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-phone-log-outline</v-icon>
                Transfer – Blind transfer/Attended transfer
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Call transfer, both Blind (without notifying the new
                  recipient) and Attended (notifying the new recipient before
                  transferring), is essential for efficient call management.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="170px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-voicemail</v-icon>
                Voice mail
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Voicemail is crucial for receiving messages when a call cannot
                  be answered, ensuring that important messages are not missed.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="170px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-account-supervisor</v-icon>
                Call pickup – directed/group
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  This feature allows users to pick up calls directed to their
                  colleagues or within a group, providing flexibility and
                  improved call handling within a team.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="170px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-card-account-phone-outline</v-icon>
                Intercom
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  The intercom feature enables quick and efficient internal
                  communication within an organization without using external
                  phone lines.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <h1 style="color: #f0f0f0" class="text-center">
          Connect to see More Information
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <h4 style="color: #f0f0f0">Unixcape Voice Information PDF File</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <v-icon style="color: #f0f0f0; font-size: 6rem">mdi-information-variant-circle</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <v-btn @click="clickToDownload" outlined color="#f68b44" class="title-case">Download File</v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.initialize();
  },
  computed: {
    isMobile() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      }
      return false;
    },
    dividerWidth() {
      const width = window.innerWidth;
      console.log("width : ", width);
      const is2K = width >= 2048 && width < 3840;
      const is4K = width >= 3840;

      if (is2K) {
        return "width: 27%";
      }

      if (is4K) {
        return "width: 27%";
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 60%";
        case "sm":
          return "width: 38%";
        case "md":
          return "width: 31%";
        case "lg":
          return "width: 33%";
        case "xl":
          return "width: 29%";
      }
    },
    isDesktop() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
          navigator.userAgent
        )
      ) {
        // some code..
        console.log("mobile detected");
        return false;
      } else {
        console.log("desktop detected");
        return true;
      }
    },
    headerWidthClass() {
      if (this.isDesktop) {
        return "xs-width";
      }
      return "";
    },
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100px";
        case "sm":
          return "200px";
        case "md":
          return "200px";
        case "lg":
          return "200px";
        case "xl":
          return "200px";
      }
    },
    justifyCommu() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "justify-center ";
        case "sm":
          return "justify-center ";
        case "md":
          return "justify-center ";
        case "lg":
          return "justify-end ";
        case "xl":
          return "justify-end ";
      }
    },
    headerClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "";
        case "sm":
          return "";
        case "md":
          return "";
        case "lg":
          return "";
        case "xl":
          return "";
      }
    },
    isSmall() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    isLarge() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
  },
  methods: {
    clickToDownload() {
      const file = this.path.find((doc) => doc._id === "UXC-Voice");
      if (file) {
        this.downloadFile(file.documentPath);
      } else {
        console.log("File not found");
      }
    },
    downloadFile(path) {
      const url = `${process.env.BASE_URL}${path}`;
      const filename = path.split("/").pop(); // Extract the filename from the path
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set only the filename in the download attribute
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    pathPdf(data) {
      for (const item of data) {
        const url = item.documentPath;
        const basePath = "/html/";
        const path = url.split(basePath)[1];
        item.documentPath = path;
        console.log("path", path);
      }
    },
    async initialize() {
      const request = "api/documents";

      await axios
        .get(request, {})
        .then((res) => {
          console.log("response : ", res);
          this.path = res.data.rows;
          this.pathPdf(this.path);
          console.log("lol", this.path);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.divider-line {
  background-color: #f68b44;
  margin-right: 1400px;
}

.divider-center {
  background-color: #f68b44;
  margin-left: 300px;
  margin-right: 300px;
}

.xs-width {
  width: 120vw;
}
</style>
