<template>
  <v-footer class="px-0" color="black" :class="headerWidthClass">
    <v-container fluid>
      <v-row class="px-5 pb-2">
        <v-col class="justify-center d-flex" :class="headerWidthClass">
          <h3 class="footer-title">Trusted By</h3>
        </v-col>
      </v-row>

      <v-row style="background-color: white" class="my-3">
        <v-col class="colClass">
          <div class="marquee-wrapper">
            <div class="marquee-content">
              <img v-for="(image, index) in images" :key="index" :src="require(`@/assets/${image.src}`)"
                :alt="image.alt" class="marquee-image" />
              <img v-for="(image, index) in images" :key="'duplicate-' + index" :src="require(`@/assets/${image.src}`)"
                :alt="image.alt" class="marquee-image" />
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-2 px-5" :class="headerWidthClass">
        <v-col :class="headerWidthClass" cols="12" sm="6" class="justify-start d-flex">
          <p class="footer-text">
            TGS Enterprise Networks Ltd<br />
            Charn Issara Tower II, 20th Floor<br />
            2922/252-255 New Petchburi Road, Bangkapi Huaykwang, Bangkok,
            10310<br /><br />
            Tel. 02 715 9200
          </p>
        </v-col>
      </v-row>

      <v-row class="mt-2 px-5">
        <v-col cols="12" md="6" class="justify-start d-flex">
          <p class="footer-text">www.tgs-enterprise.com</p>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-sm-start justify-md-end">
          <p class="footer-text d-flex align-center">
            Social Network:
            <a href="https://www.facebook.com/TGSThai" target="_blank" class="ml-2">
              <v-img :src="require('@/assets/facebook.png')" alt="Facebook" style="width: 24px; height: 24px;"></v-img>
            </a>
            <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=tgsenterprise" target="_blank" class="ml-2">
              <v-img :src="require('@/assets/pngegg.png')" alt="Line" style="width:  24px; height: 24px;"></v-img>
            </a>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      images: [
        { src: "1.png", alt: "Image 1" },
        { src: "2.png", alt: "Image 2" },
        { src: "3.png", alt: "Image 3" },
        { src: "4.png", alt: "Image 4" },
        { src: "5.png", alt: "Image 5" },
        { src: "6.png", alt: "Image 6" },
        { src: "7.png", alt: "Image 7" },
        { src: "8.png", alt: "Image 8" },
        { src: "9.png", alt: "Image 9" },
        { src: "10.png", alt: "Image 10" },
        { src: "11.png", alt: "Image 11" },
        { src: "12.png", alt: "Image 12" },
        { src: "13.png", alt: "Image 13" },
        { src: "14.png", alt: "Image 14" },
        { src: "15.png", alt: "Image 15" },
        { src: "16.png", alt: "Image 16" },
        { src: "17.png", alt: "Image 17" },
        { src: "18.png", alt: "Image 18" },
        { src: "19.png", alt: "Image 19" },
        { src: "20.png", alt: "Image 20" },
        { src: "21.png", alt: "Image 21" },
        { src: "22.png", alt: "Image 22" },
        { src: "23.png", alt: "Image 23" },
        { src: "24.png", alt: "Image 24" },
        { src: "25.png", alt: "Image 25" },
        { src: "26.png", alt: "Image 26" },
        { src: "27.png", alt: "Image 27" },
        { src: "28.png", alt: "Image 28" },
        { src: "29.png", alt: "Image 29" },
        { src: "30.png", alt: "Image 30" },
        { src: "31.png", alt: "Image 31" },
        { src: "32.png", alt: "Image 32" },
        { src: "33.png", alt: "Image 33" },
        { src: "34.png", alt: "Image 34" },
        { src: "35.png", alt: "Image 35" },
        { src: "36.png", alt: "Image 36" },
        { src: "37.png", alt: "Image 37" },
        { src: "38.png", alt: "Image 38" },
        { src: "39.png", alt: "Image 39" },
        { src: "40.png", alt: "Image 40" },
        { src: "41.png", alt: "Image 41" },
        { src: "42.png", alt: "Image 42" },
        { src: "43.png", alt: "Image 43" },
      ],
      currentBatch: 0,
      imagesPerRow: 0,
    };
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    isDesktop() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
          navigator.userAgent
        )
      ) {
        // some code..
        console.log("mobile detected");
        return false;
      } else {
        console.log("desktop detected");
        return true;
      }
    },
    headerWidthClass() {
      if (this.isDesktop) {
        if (this.$vuetify.breakpoint.xs) {
          return "xs-width";
        }
      }
      return "";
    },
    visibleImages() {
      return this.images.slice(
        this.currentBatch * this.imagesPerRow,
        (this.currentBatch + 1) * this.imagesPerRow
      );
    },
  },
  mounted() {
    this.updateImagesPerRow();

    // Recalculate the number of images on window resize
    window.addEventListener("resize", this.updateImagesPerRow);
  },

  methods: {
    updateImagesPerRow() {
      const imageWidth = 150; // Width of each image in pixels
      const containerWidth =
        this.$el.querySelector(".marquee-wrapper").clientWidth;
      this.imagesPerRow = Math.floor(containerWidth / imageWidth);
    },

    // Method to rotate images: Remove the first image and append a new one at the end
    rotateImages() {
      // Shift the first image out of the array
      const removedImage = this.images.shift();
      // Add the removed image back to the end of the array
      this.images.push(removedImage);
    },
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateImagesPerRow);
  },
};
</script>

<style scoped>
.footer-title {
  color: #f0f0f0;
  font-size: 1.7rem;
  /* Default font size */
}

.footer-text {
  color: #f0f0f0;
  font-size: 1rem;
  /* Default font size */
}

/* Responsive font size */
@media (max-width: 600px) {
  .footer-title {
    font-size: 1.3rem;
    /* Smaller font for mobile */
  }

  .footer-text {
    font-size: 0.7rem;
    /* Smaller font for mobile */
  }
}

.xs-width {
  width: 120vw;
}

.marquee-wrapper {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  /* Ensure it doesn't overflow */
}

.marquee-content {
  display: inline-flex;
  animation: slide-left 300s linear infinite;
  /* Duration adjusted for many images */
}

.marquee-image {
  display: inline-block;
  width: 140px !important;
  height: 80px !important;
  object-fit: contain !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  padding: 5px !important;
  border-radius: 8px !important;
  text-align: center !important;
}

/* Responsive font size */
@media (max-width: 600px) {
  .marquee-image {
    display: inline-block;
    width: 80px !important;
    height: 40px !important;
    object-fit: contain !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
    padding: 5px !important;
    border-radius: 8px !important;
    text-align: center !important;
  }

  .colClass {
    padding: 0px;
    padding-top: 5px;
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
    /* Slide full width of content */
  }
}
</style>
