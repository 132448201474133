<template>
  <v-container fluid :class="headerWidthClass">
    <v-row class="d-flex justify-center">
      <v-col cols="10" lg="8" class="justify-start d-flex" style="color: #f68b44; margin-top: 40px">
        <h1>CRM</h1>
      </v-col>
    </v-row>
    <v-row class="justify-start d-flex">
      <v-col cols="12" lg="12">
        <v-divider class="custom-height" :style="dividerWidth" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6" class="justify-center text-section mt-6">
        <v-row class="d-flex" :class="isLarge ? 'justify-center' : 'justify-center'">
          <v-col cols="10" md="10" lg="10" :class="isSmall ? 'justify-center d-flex' : 'justify-start d-flex'">
            <h2 :class="headerClass">CRM</h2>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-center' : 'justify-center'">
          <v-col cols="10" md="10" lg="10" class="justify-center d-flex">
            <p class="text-start">
              CRM or Customer Relationship Management is a system used by
              organizations to manage and analyze customer interactions and data
              throughout the customer lifecycle. The primary goal is to improve
              customer relationships, retain customers, and drive business
              growth.<br /><br />

              CRM is designed to elevate your business operations by offering
              versatile, easy-to-implement solutions that streamline processes,
              optimize workflows, and enhance customer interactions at every
              touchpoint.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="12" :sm="12" :md="12" :lg="3" :xl="3" :xxl="3">
        <v-row class="d-flex" :class="justifyCommu">
          <v-col cols="12" class="d-flex" :class="justifyCommu">
            <div class="d-flex" :class="justifyCommu" :style="isMobile ? 'width:350px; ' : 'width:450px'">
              <v-img width="50%" src="../assets/home6.png"></v-img>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6">
        <v-row class="d-flex" :class="isSmall ? 'justify-center' : 'justify-end mt-n10'">
          <v-col cols="8" class="d-flex" :class="justifyCommu">
            <v-img width="90%" src="../assets/solution crm.png"></v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6" class="text-section">
        <v-row class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start  ml-3'">
          <v-col cols="8" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <h2 class="text-start">Solution Overview</h2>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-start ml-3' : 'justify-center'">
          <v-col cols="10" xl="9" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <p>
              After an extensive comparison, UniXcapeCRM was chosen for its
              superior performance. Within a month, it implemented key
              requirements, becoming the central system for managing processes
              and information for customers and partners. UniXcapeCRM speeds up
              contact with potential customers, ensures no inquiries are
              forgotten, and processes requests efficiently. It streamlines
              invoice creation, reducing errors, and allows semi-automatic
              sending of payment and invoice reminders. Notifications and
              reminders help employees complete tasks on time, while the
              system's reporting capabilities monitor KPIs for customer
              acquisition and service provision. Overall, UniXcapeCRM enhances
              customer contact, request processing, invoice management, task
              completion, and provides valuable insights.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="8" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
        <h2 class="text-start" style="color: white">Features</h2>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col :cols="11" lg="8" class="justify-center d-flex">
        <v-row class="d-flex justify-start" :class="isLarge ? 'mt-8' : 'mt-5'">
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-application-brackets-outline</v-icon>
                Integration with Redmine
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  CRM is integrated with the issue tracking system Redmine to
                  synchronize information regarding completed work.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-bank</v-icon>
                Integration with Accounting Software
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  CRM is integrated with accounting software to enable the
                  exchange of invoices<br />
                  and payments received.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-folder-plus-outline</v-icon>
                Data Integration from Old Excel Solutions
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Data about existing customers and partners from old Excel
                  solutions was<br />
                  integrated into CRM
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-folder-plus-outline</v-icon>
                Data Integration from Old Excel Solutions
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Data about existing customers and partners from old Excel
                  solutions was<br />
                  integrated into CRM
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-text-box-multiple-outline</v-icon>
                Creation of New Reports
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  New reports were created to measure work effectiveness.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-plus-circle-outline</v-icon>
                Creation of New Entities and Relationships
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  New entities and relationships with existing entities have
                  been created, including resources, projects, epics, user
                  stories, issues, payments, invoices, invoice items, and
                  products.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-send-check-outline</v-icon>
                Sending Invoices and Payment Reminders
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Sending of invoices, invoice reminders, and payment reminders
                  is based on ready-to-use multi-lingual templates. The
                  documents are attached as PDFs to the corresponding email.
                  Alternatively, these can be printed out with the cover letter
                  and sent by post.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-text-box-check-outline</v-icon>
                Types of Invoices
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  You can create three types of invoices: invoices, prepayment
                  invoices, and invoice corrections.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-currency-usd</v-icon>
                Automatic Creation of Cost Statements
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  The system enables the automatic creation of cost statements
                  to agree with the customer.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-file-document-edit-outline</v-icon>Recording Product Sales
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Information about products sold is recorded in CRM
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-responsive</v-icon>
                Clear Assignment of Responsibility
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Due to the entity assignment, it is always clear who is
                  responsible for what.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-apache-kafka</v-icon>Automation of Many Steps
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Many steps have been automated to save employees' time.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <h1 style="color: #f0f0f0" class="text-center">
          Connect to see More Information
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <h4 style="color: #f0f0f0">CRM Information PDF File</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <v-icon style="color: #f0f0f0; font-size: 6rem">mdi-information-variant-circle</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <v-btn @click="clickToDownload" outlined color="#f68b44" class="title-case">Download File</v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  computed: {
    dividerWidth() {
      const width = window.innerWidth;
      console.log("width : ", width);
      const fullHd = width >= 1920 && width < 2048;
      const is2K = width >= 2048 && width < 3840;
      const is4K = width >= 3840;

      if (is2K) {
        return "width: 27%";
      }

      if (is4K) {
        return "width: 27%";
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 25%";
        case "sm":
          return "width: 18%";
        case "md":
          return "width: 15%";
        case "lg":
          return "width: 22%";
        case "xl":
          return "width: 21%";
      }
    },
    isDesktop() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
          navigator.userAgent
        )
      ) {
        // some code..
        console.log("mobile detected");
        return false;
      } else {
        console.log("desktop detected");
        return true;
      }
    },
    headerWidthClass() {
      if (this.isDesktop) {
        return "xs-width";
      }
      return "";
    },
    scrollWrapperClass() {
      // Apply scroll only on 'xs' screens
      return this.$vuetify.breakpoint.name === "xs"
        ? "scroll-wrapper-overflow-x"
        : "";
    },
    isMobile() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      }
      return false;
    },
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100px";
        case "sm":
          return "200px";
        case "md":
          return "200px";
        case "lg":
          return "200px";
        case "xl":
          return "200px";
      }
    },
    justifyCommu() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "justify-center ";
        case "sm":
          return "justify-center ";
        case "md":
          return "justify-center ";
        case "lg":
          return "justify-end  mt-5";
        case "xl":
          return "justify-end  mt-5";
      }
    },
    headerClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "";
        case "sm":
          return "";
        case "md":
          return "";
        case "lg":
          return "";
        case "xl":
          return "";
      }
    },
    isSmall() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    isLarge() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    clickToDownload() {
      const file = this.path.find((doc) => doc._id === "CRM");
      if (file) {
        this.downloadFile(file.documentPath);
      } else {
        console.log("File not found");
      }
    },
    downloadFile(path) {
      const url = `${process.env.BASE_URL}${path}`;
      const filename = path.split("/").pop(); // Extract the filename from the path
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set only the filename in the download attribute
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    pathPdf(data) {
      for (const item of data) {
        const url = item.documentPath;
        const basePath = "/html/";
        const path = url.split(basePath)[1];
        item.documentPath = path;
        console.log("path", path);
      }
    },
    async initialize() {
      const request = "api/documents";

      await axios
        .get(request, {})
        .then((res) => {
          console.log("response : ", res);
          this.path = res.data.rows;
          this.pathPdf(this.path);
          console.log("lol", this.path);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.divider-line {
  background-color: #f68b44;
  margin-right: 1400px;
}

.divider-center {
  background-color: #f68b44;
  margin-left: 300px;
  margin-right: 300px;
}

.title-case {
  text-transform: capitalize;
}

.xs-width {
  width: 120vw;
}
</style>
