<template>
  <v-container fluid>
    <v-row class="d-flex justify-center">
      <v-col cols="10" lg="8" class="justify-start d-flex" style="color: #f68b44; margin-top: 40px">
        <h1>News & Facilities</h1>
      </v-col>
    </v-row>
    <v-row class="justify-start d-flex">
      <v-col cols="12" lg="12">
        <v-divider :style="dividerWidth" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row class="justify-start d-flex px-5">
      <v-col cols="12" md="6" lg="6" class="d-flex justify-center">
        <!-- <v-btn outlined color="#f0f0f0" class="mb-2 mx-2"> ALL </v-btn>
        <v-btn outlined color="#f0f0f0" class="mb-2 mx-2"> NEWS </v-btn>
        <v-btn outlined color="#f0f0f0" class="mb-2 mx-2"> FACILITIES </v-btn> -->
      </v-col>
      <v-col cols="12" md="6" lg="6" class="text-white justify-center d-flex">
        <v-text-field dark v-model="search" class="custom-border text" outlined label="Search" dense hide-details
          style="max-width: 400px">
          <template v-slot:label>
            <v-icon left>mdi-magnify</v-icon>
            <span>Search</span>
          </template>
        </v-text-field>
        <v-alert v-if="filteredItems.length === 0 && search.length > 0" type="info" color="grey lighten-2" class="mt-4">
          No results found
        </v-alert>
      </v-col>
    </v-row>
    <!-- Conditional Rendering for Cards -->
    <v-row class="mt-10 justify-center pa-0 pb-5">
      <v-col xs="12" sm="10" md="10" lg="8" xl="8">
        <v-row :class="positionClass">
          <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3" v-for="(item, index) in truncatedDescription"
            :key="item.title" class="d-flex justify-center " :class="colClass">
            <v-card class="  news-card d-flex flex-column " @mouseenter="setHover(item.title)"
              @mouseleave="setHover(null)" @click="goToDetails(item.title)"
              style="border-radius: 20px; height: 100%; width:100%">
              <v-img :src="showImage(item.image)" v-if="item.image" style="height: 250px;"></v-img>
              <v-card-title class="text-white">{{ item.title }}</v-card-title>
              <v-card-text style="color: white" v-html="item.truncatedDescription"></v-card-text>
              <v-btn style="color: white; width: 120px;" @click.stop="goToDetails(item.title)" :class="{
                'hover-underline': hovereditemTitle === item.title,
                'title-case': true,
              }" text class="mb-3 justify-start">
                Read more
              </v-btn>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      news: [],
      total: [],
      items: [
        {
          title: "Workshop held in Hua Hin",
          description:
            "This Workshop held in Hua Hin, Thailand for 3 days. Starting from 12 January 2015 to 14 January 2015. All participants ...",
          image: "WorkshopNews.png",
          route: "news/workshop",
        },
        {
          title: "Workshop ",
          description:
            "This Workshop held in Hua Hin, Thailand for 3 days. Starting from 12 January 2015 to 14 January 2015. All participants ...",
          image: "WorkshopNews.png",
          route: "news/workshop",
        },
        {
          title: "Workshop held in",
          description:
            "This Workshop held in Hua Hin, Thailand for 3 days. Starting from 12 January 2015 to 14 January 2015. All participants ...",
          image: "WorkshopNews.png",
          route: "news/workshop",
        },
        {
          title: "Workshop held in Hua ",
          description:
            "This Workshop held in Hua Hin, Thailand for 3 days. Starting from 12 January 2015 to 14 January 2015. All participants ...",
          image: "WorkshopNews.png",
          route: "news/work",
        },
        // {
        //   title: "Workshop ",
        //   description:
        //     "This Workshop held in Hua Hin, Thailand for 3 days. Starting from 12 January 2015 to 14 January 2015. All participants ...",
        //   image: "WorkshopNews.png",
        //   route: "news/workshop",
        // },
        // {
        //   title: "Hin",
        //   description:
        //     "This Workshop held in Hua Hin, Thailand for 3 days. Starting from 12 January 2015 to 14 January 2015. All participants ...",
        //   image: "WorkshopNews.png",
        //   route: "news/workshop",
        // },
      ],
      hovereditemTitle: null,
      search: "",
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    publishedItems() {
      return this.filteredItems.filter(item => item.status === 'Publish');
    },
    truncatedDescription() {
      return this.publishedItems.map(item => {
        if (item.description.length > 60) {
          return {
            ...item,
            truncatedDescription: item.description.slice(0, 60) + '...',
          };
        } else {
          return {
            ...item,
            truncatedDescription: item.description,
          };
        }
      });
    },
    dividerWidth() {
      const width = window.innerWidth;
      console.log("width : ", width);
      const is2K = width >= 2048 && width < 3840;
      const is4K = width >= 3840;

      if (is2K) {
        return "width: 27%";
      }

      if (is4K) {
        return "width: 27%";
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 67%";
        case "sm":
          return "width: 41%";
        case "md":
          return "width: 33%";
        case "lg":
          return "width: 34%";
        case "xl":
          return "width: 30%";
      }
    },
    showBlankCols() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    justifyContent() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "justify-center";
        case "sm":
          return "justify-center";
        case "md":
          return "justify-center";
        case "lg":
          return "justify-start";
        case "xl":
          return "justify-center";
      }
    },
    isDesktop() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
          navigator.userAgent
        )
      ) {
        // some code..
        console.log("mobile detected");
        return false;
      } else {
        console.log("desktop detected");
        return true;
      }
    },
    colClass() {
      if (!this.isDesktop) {
        if (this.$vuetify.breakpoint.xs) {
          return "px-4 pb-0";
        }
      }
      return "";
    },
    positionClass() {
      return this.$vuetify.breakpoint.name === "xs"
        ? "d-flex justify-center"
        : "d-flex justify-start";
    },
    filteredItems() {
      if (this.search) {
        const searchTerm = this.search.toLowerCase();
        return this.news.filter((item) =>
          item.title.toLowerCase().includes(searchTerm)
        );
      }

      console.log("newss ---->", this.news)
      return this.news;
    },
  },
  methods: {
    // showImage(path) {
    //   const url = `${process.env.BASE_URL}${path}`;
    //   return url
    // },
    pathImage(data) {
      for (const item of data) {
        const url = item.image
        const basePath = "/html/";
        const path = url.split(basePath)[1];
        item.image = path
        console.log("path", path)
        item.route = `news/${item.title}`
      }
    },
    async initialize() {
      const request = "api/news/publish";

      await axios
        .get(request, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("response : ", res);
          this.news = res.data
          this.total = res.data.total;
          this.pathImage(this.news)
          console.log("news", this.news)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goToDetails(title) {
      this.$router.push({ name: 'Detail', params: { title } });
    },
    showImage(image) {
      return image ? `${process.env.BASE_URL}${image}` : 'default-image.jpg';
    },
    transfer(route) {
      this.$router.push(route);
    },
    setHover(item) {
      this.hovereditemTitle = item;
    },


    // transfer(item) {
    //   if (item) {
    //     this.$router.push({ path: item });
    //   } else {
    //     console.log("Route not defined for this product");
    //     // Optionally, handle products without a route
    //   }
    // },
  },
};
</script>

<style>
.head-divider {
  background-color: #f68b44;
  margin-right: 1460px;
}

.title-case {
  text-transform: capitalize;
}

.custom-border .v-input__control {
  border-color: rgb(0, 0, 0) !important;
  /* Change to desired border color */
}

.text input {
  color: white !important;
}

.hover-underline {
  text-decoration: underline !important;
}

.text-orange {
  color: #f68b44;
}

.text-white {
  color: #f0f0f0;
}

.news-card {
  background: linear-gradient(180deg, #e6a645 0%, #d76f3c 100%);
  width: 90%;
  height: 100%;
  border-radius: 14px;
}

@media (min-width: 1920px) {
  .ml-2k-10 {
    margin-left: 10%;
  }

  .ml-2k-20 {
    margin-left: 20%;
  }

  .mr-2k-20 {
    margin-right: 20%;
  }
}
</style>