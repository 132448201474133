<template>
  <v-container>
    <v-row class="d-flex justify-end">
      <v-col cols="10" xs="6" sm="6" lg="6" xl="6" class="justify-start d-flex"
        style="color: #f68b44; margin-top: 40px">
        <h1>About UniXcape</h1>
      </v-col>
    </v-row>
    <v-row class="justify-end d-flex">
      <v-col cols="12" xs="6" sm="6" lg="6" xl="6">
        <v-divider :style="dividerWidth" color="#f68b44"></v-divider>
      </v-col>
    </v-row>

    <v-row class="justify-end">
      <v-col xs="12" sm="12" md="12" lg="5" xl="5" xxl="5" class="d-flex justify-center mt-4">
        <div class="justify-end d-flex" :style="imageSize">
          <v-img class="" src="../assets/about.png"></v-img>
        </div>
      </v-col>

      <v-col xs="12" sm="10" md="12" lg="6" xl="6" xxl="6" class="mt-4 text-start" style="color: #f0f0f0">
        <v-row class="d-flex" :class="isLarge ? 'justify-start' : 'justify-center'">
          <v-col cols="10" md="10" lg="9" class="justify-start d-flex">
            <p class="justify-center d-flex">
              TGS Enterprise Networks provides solutions and services for
              Enterprise Customers in the field of telecommunication
              and IT, including:
            </p>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-start' : 'justify-center'">
          <v-col cols="10" md="10" lg="9" class="justify-start d-flex">
            <ul class="">
              <li>Enterprise Communication Networks</li>
              <li>Broadband Communication Systems</li>
              <li>Energy Management Solutions</li>
              <li>IT Equipment Services and Solutions</li>
              <li>Cellular Networks Installation and Maintenance Services</li>
              <br />
            </ul>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-start' : 'justify-center'">
          <v-col cols="10" md="10" lg="9" class="justify-center d-flex">
            <p class="justify-center d-flex">
              UniXcape is the communication software solution to enhance customer
              capability in communication. Based on open standard, UniXcape can be
              connected to legacy PBX as well as modern Softswitch to add more
              functionality and efficiency. UniXcape Family consists of 6
              solutions:
            </p>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-start' : 'justify-center'">
          <v-col cols="10" md="10" lg="9" class="justify-start d-flex">
            <ul class="">
              <li>UniXcape Voice, the future voice communication platform.</li>
              <li>UniXcape Commu, mobilize your workplace.</li>
              <li>
                Omnichannel Contact Center, the modular and flexible contact
                center
              </li>
              <li>UniXcape Meet, the visual conference.</li>
              <li>UniXcape Voice + Commu</li>
              <li>CRM</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="9">
        <v-divider class="my-5" color="#f68b44"></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3" class="d-flex justify-center">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <div style="width: 30%">
              <v-img class="mt-4 " src="../assets/about1.png"></v-img>
            </div>
          </v-col>
          <v-col cols="12" class="text-center">
            <h1 class="justify-center d-flex " style="color: #f68b44">
              VISION
            </h1>
            <br />
            <p v-html="textvision" style="color: #f0f0f0" class="justify-center d-flex">
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3" class="d-flex justify-center">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <div style="width: 30%">
              <v-img class="mt-4 " src="../assets/about2.png"></v-img>
            </div>
          </v-col>
          <v-col cols="12" class="text-center ">
            <h1 class="justify-center d-flex" style="color: #f68b44">
              MISSION
            </h1>
            <br />
            <p style="color: #f0f0f0" v-html="textMission">
            </p>
          </v-col>
        </v-row></v-col>
      <v-col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3" class="d-flex justify-center">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <div style="width: 30%">
              <v-img class="mt-4 " src="../assets/about3.png"></v-img>
            </div>
          </v-col>
          <v-col cols="12" class="text-center mt-3">
            <h1 class="justify-center d-flex" style="color: #f68b44">
              VALUE
            </h1>
            <br />
            <p style="color: #f0f0f0" v-html="textValue">

            </p>
          </v-col>
        </v-row></v-col>
    </v-row>

    <v-row class="justify-center d-flex">
      <v-col cols="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    dividerWidth() {
      const width = window.innerWidth;
      console.log("width : ", width);
      const is2K = width >= 2048 && width < 3840;
      const is4K = width >= 3840;

      if (is2K) {
        return "width: 100%";
      }

      if (is4K) {
        return "width: 100%%";
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 74%";
        case "sm":
          return "width: 100%";
        case "md":
          return "width: 100%";
        case "lg":
          return "width: 100%";
        case "xl":
          return "width: 100%";
      }
    },
    isLarge() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
    imageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 300px; height: 240px";
        case "sm":
          return "width: 500px; height: 400px";
        case "md":
          return "width: 850px; height: 400px";
        case "lg":
          return "width: 500px; height: 400px";
        case "xl":
          return "width: 600px; height: 450px";
      }
    },
    textValue() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return ` Customers are assured that they are working
              with a partner that not only understands
              their local market, but also has an in-depth knowledge
              of the product suites, ensuring that customers will have
              seamless continuity of service.`;
        case "sm":
          return ` Customers are assured that they are working<br />
              with a partner that not only understands<br />
              their local market, but also has an in-depth knowledge<br />
              of the product suites, ensuring that customers will have<br />
              seamless continuity of service.`;
        case "md":
          return ` Customers are assured that they are working<br />
              with a partner that not only understands<br />
              their local market, but also has an in-depth knowledge<br />
              of the product suites, ensuring that customers will have<br />
              seamless continuity of service.`;
        case "lg":
          return ` Customers are assured that they are working<br />
              with a partner that not only understands<br />
              their local market, but also has an in-depth knowledge<br />
              of the product suites, ensuring that customers will have<br />
              seamless continuity of service.`;
        case "xl":
          return ` Customers are assured that they are working<br />
              with a partner that not only understands<br />
              their local market, but also has an in-depth knowledge<br />
              of the product suites, ensuring that customers will have<br />
              seamless continuity of service.`;
      }
    },
    textMission() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return `To provide state-of-the-art solutions
              based on a well-established technology and to
              respond to customers’ requirements and deliver service
              with quality and speed.`;
        case "sm":
          return `To provide state-of-the-art solutions<br />
              based on a well-established technology and to<br />
              respond to customers’ requirements and deliver service<br />
              with quality and speed.`;
        case "md":
          return `To provide state-of-the-art solutions<br />
              based on a well-established technology and to<br />
              respond to customers’ requirements and deliver service<br />
              with quality and speed.`;
        case "lg":
          return ` To provide state-of-the-art solutions<br />
              based on a well-established technology and to<br />
              respond to customers’ requirements and deliver service<br />
              with quality and speed.`;
        case "xl":
          return ` To provide state-of-the-art solutions<br />
              based on a well-established technology and to<br />
              respond to customers’ requirements and deliver service<br />
              with quality and speed.`;
      }
    },
    textvision() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return `To become a globally recognized company
              providing integrated IT and Communication Solution
              and Service to Enterprise Customers.`;
        case "sm":
          return `To become a globally recognized company<br />
              providing integrated IT and Communication Solution<br />
              and Service to Enterprise Customers.`;
        case "md":
          return `To become a globally recognized company<br />
              providing integrated IT and Communication Solution<br />
              and Service to Enterprise Customers.`;
        case "lg":
          return `To become a globally recognized company<br />
              providing integrated IT and Communication Solution<br />
              and Service to Enterprise Customers.`;
        case "xl":
          return `To become a globally recognized company<br />
              providing integrated IT and Communication Solution<br />
              and Service to Enterprise Customers.`;
      }
    }
  },
};
</script>

<style>
.custom-divider-start {
  background-color: #f68b44;
  /* margin-left: 950px; */
  margin-top: 10px;
}
</style>
