<template>
  <hello-world />
</template>

<script>
import HelloWorld from '../components/Home'

export default {
  name: 'Home',

  components: {
    HelloWorld,
  },
}
</script>
