<template>
  <v-container fluid :class="headerWidthClass">
    <v-row class="d-flex justify-center">
      <v-col cols="10" md="9" lg="8" class="justify-start d-flex" style="color: #f68b44; margin-top: 40px">
        <h1>UniXcape Meet</h1>
      </v-col>
    </v-row>
    <v-row class="justify-start d-flex">
      <v-col cols="12" lg="12">
        <v-divider :style="dividerWidth" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col :cols="12" :sm="12" :md="12" :lg="5" :xl="5" :xxl="5">
        <v-row class="d-flex" :class="justifyCommu">
          <v-col cols="8" class="d-flex" :class="justifyCommu">
            <div class="d-flex" :class="justifyCommu" :style="isMobile ? 'width:350px; ' : 'width:500px'">
              <v-img width="10%" src="../assets/home4.png"></v-img>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6" class="text-section">
        <v-row class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start  ml-10'">
          <v-col cols="8" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <h2 class="text-start">UniXcape Meet</h2>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-start ml-10' : 'justify-center'">
          <v-col cols="10" xl="10" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <p>
              UniXcape Meet is a video conferencing which empower users to use
              and deploy video conferencing platforms with state-of-the-art
              video quality and features. UniXcape Meet is a web conferencing
              solution for small to enterprise level businesses. It is a video
              conferencing platform (a web application that does not require to
              install any client software app or plugin). It is a stable and
              reliable video conferencing solution that can be deployed in
              minutes at any time on an inexpensive server (no special
              horsepower requirements; no need for any particular data storage,
              configuration, interfacing with external applications etc.). And
              it is very easy to use on client side, too
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6" class="justify-center text-section">
        <v-row class="d-flex" :class="isSmall ? 'justify-center' : 'justify-center  ml-16'">
          <v-col cols="10" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <h2 class="text-start">Solution Overview</h2>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-center ml-16' : 'justify-center'">
          <v-col cols="10" xl="10" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <p style="color: white">
              UniXcape Meet is a cutting-edge video conferencing platform
              designed to cater to businesses of all sizes, from small
              enterprises to large corporations. This web-based application
              offers state-of-the-art video quality and a suite of advanced
              features, providing a stable, reliable, and user-friendly solution
              for all your video conferencing needs.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="12" :sm="12" :md="12" :lg="4" :xl="4" :xxl="4">
        <v-row class="d-flex justify-center">
          <v-col cols="9" class="d-flex justify-center">
            <div class="d-flex" :class="justifyCommu" :style="isMobile ? 'width:250px;' : 'width:450px'">
              <v-img width="10%" src="../assets/meet solution.png"></v-img>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6" class="text-section">
        <v-row class="d-flex" :class="isLarge ? 'justify-end' : 'justify-center'">
          <v-col cols="8" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <h2 class="text-start">Security</h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col :cols="12" :xs="12" :sm="12" :md="12" :lg="8" :xl="8" :xxl="8" class="justify-center text-section mt-3">
        <v-row class="d-flex" :class="isLarge ? 'justify-start' : 'justify-center'">
          <v-col cols="10" md="10" xl="12" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <p class="text-start">
              You can turn on End-to-End encryption (e2ee) as long as you are
              using UniXcape Meet on a browser with support for insertable
              streams. Currently, this means any browser based on Chromium 83
              and above, including Microsoft Edge, Google Chrome, Brave and
              Opera<br /><br />

              The good news is that UniXcape Meet offers very strong protection
              even without e2ee.<br /><br />

              All audio and video traffic is encrypted on the network (using
              DTLS-SRTP). This outer layer of DTLS-SRTP encryption is removed
              while packets are traversing UniXcape Videobridge; however they
              are never stored to any persistent storage and only live in memory
              while being routed to other participants in the meeting.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="8" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
        <h2 class="text-start" style="color: white">Features</h2>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col :cols="11" lg="8" class="justify-center d-flex">
        <v-row class="d-flex justify-start" :class="isLarge ? 'mt-8' : 'mt-5'">
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-application-outline</v-icon>
                Support for Major Browsers
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  UniXcape Meet works seamlessly across all major web browsers,
                  ensuring accessibility without the need for additional
                  software installations.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-cellphone</v-icon>
                Mobile Applications
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Stay connected on the go with dedicated mobile applications
                  for both iOS and Android, allowing you to join meetings from
                  anywhere.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-play-box-multiple-outline</v-icon>
                HD Audio and Video
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Experience crystal clear audio and high-definition video for
                  more engaging and effective communication.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-cloud-print-outline</v-icon>
                Content Sharing
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Share your screen, documents, or other content in real-time,
                  facilitating more interactive and collaborative sessions.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-application-brackets-outline</v-icon>
                Web and Native SDKs for Integration
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Easily integrate UniXcape Meet into your own applications
                  using provided SDKs, whether you need web-based or native
                  solutions.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title"><v-icon class="mr-3"
                  style="color: white">mdi-clipboard-text-outline</v-icon>
                Polls
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Conduct polls during your meetings to gather feedback, make
                  decisions, or keep participants engaged.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-account-switch-outline</v-icon>
                Unlimited Meeting Access for up to 100 Participants
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Host unlimited meetings with up to 100 participants at a time,
                  making it ideal for<br />
                  both small and medium-sized gatherings.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-hand-front-left-outline</v-icon>Raise Hand and Reactions
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Use the raise hand feature to manage questions and
                  interactions, and express yourself with real-time reactions.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-message-outline</v-icon>
                Chat
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Communicate via text during meetings with a built-in chat
                  feature, perfect for<br />
                  sharing links, notes, or having side conversations without
                  interrupting the flow of<br />
                  the meeting.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-table-large</v-icon>
                Virtual Backgrounds
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Customize your video background with virtual backgrounds,
                  adding a professional touch or hiding any distractions behind
                  you.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="180px">
              <v-card-title class="feature-text-title"><v-icon class="mr-3"
                  style="color: white">mdi-radiobox-marked</v-icon>
                Recording
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Record your meetings to review later, share with absentees, or
                  use for future reference and documentation.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <h1 style="color: #f0f0f0" class="text-center">
          Connect to see More Information
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <h4 style="color: #f0f0f0">Unixcape Meet Information PDF File</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <v-icon style="color: #f0f0f0; font-size: 6rem">mdi-information-variant-circle</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <v-btn @click="clickToDownload" outlined color="#f68b44" class="title-case">Download File</v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  computed: {
    isMobile() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      }
      return false;
    },
    dividerWidth() {
      const width = window.innerWidth;
      console.log("width : ", width);
      const fullHd = width >= 1920 && width < 2048;
      const is2K = width >= 2048 && width < 3840;
      const is4K = width >= 3840;

      if (is2K) {
        return "width: 27%";
      }

      if (is4K) {
        return "width: 27%";
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 60%";
        case "sm":
          return "width: 37%";
        case "md":
          return "width: 34%";
        case "lg":
          return "width: 32%";
        case "xl":
          return "width: 28%";
      }
    },
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100px";
        case "sm":
          return "200px";
        case "md":
          return "200px";
        case "lg":
          return "200px";
        case "xl":
          return "200px";
      }
    },
    justifyCommu() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "justify-center ";
        case "sm":
          return "justify-center ";
        case "md":
          return "justify-center ";
        case "lg":
          return "justify-end ";
        case "xl":
          return "justify-end ";
      }
    },
    headerClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "";
        case "sm":
          return "";
        case "md":
          return "";
        case "lg":
          return "";
        case "xl":
          return "";
      }
    },
    isSmall() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    isDesktop() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
          navigator.userAgent
        )
      ) {
        // some code..
        console.log("mobile detected");
        return false;
      } else {
        console.log("desktop detected");
        return true;
      }
    },
    headerWidthClass() {
      if (this.isDesktop) {
        return "xs-width";
      }
      return "";
    },
    isLarge() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    clickToDownload() {
      const file = this.path.find((doc) => doc._id === "UXC-Meet");
      if (file) {
        this.downloadFile(file.documentPath);
      } else {
        console.log("File not found");
      }
    },
    downloadFile(path) {
      const url = `${process.env.BASE_URL}${path}`;
      const filename = path.split("/").pop(); // Extract the filename from the path
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set only the filename in the download attribute
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    pathPdf(data) {
      for (const item of data) {
        const url = item.documentPath;
        const basePath = "/html/";
        const path = url.split(basePath)[1];
        item.documentPath = path;
        console.log("path", path);
      }
    },
    async initialize() {
      const request = "api/documents";

      await axios
        .get(request, {})
        .then((res) => {
          console.log("response : ", res);
          this.path = res.data.rows;
          this.pathPdf(this.path);
          console.log("lol", this.path);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.divider-di {
  background-color: #f68b44;
  margin-right: 1435px;
}

.divider-center {
  background-color: #f68b44;
  margin-left: 300px;
  margin-right: 300px;
}

.xs-width {
  width: 120vw;
}
</style>
