import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContactUs from '../views/ContactUs.vue'
import About from '../views/About.vue'
import News from '../views/NewAndFacilities.vue'
import Commu from '../views/Commu.vue'
import Detail from '../views/News.vue'
import Voice from '@/views/Voice.vue'
import Meet from '@/views/Meet.vue'
import ContactCenter from '@/views/Contact-center'
import CommuVoice from '@/views/Commu+voice.vue'
import Crm from '@/views/Crm.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactUs
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/news/:title',
    name: 'Detail',
    component: Detail,
    props: true
  },
  {
    path: '/commu',
    name: 'commu',
    component: Commu
  },
  {
    path: '/voice',
    name: 'voice',
    component: Voice
  },
  {
    path: '/meet',
    name: 'meet',
    component: Meet
  },
  {
    path: '/contact-center',
    name: 'contactCenter',
    component: ContactCenter
  },
  {
    path: '/voice+commu',
    name: 'commuVoice',
    component: CommuVoice
  },
  {
    path: '/crm',
    name: 'crm',
    component: Crm
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/LoginView.vue')
    }
  },
  {
    path: '/admin/news',
    name: 'adminNews',
    component: function () {
      return import('../views/News/NewsView.vue')
    }
  },
  {
    path: '/admin/news/create',
    name: 'adminNewsCreate',
    component: function () {
      return import('../views/News/NewsCreate.vue')
    }
  },
  {
    path: '/admin/news/edit',
    name: 'adminNewsEdit',
    component: function () {
      return import('../views/News/NewsEdit.vue')
    }
  },
  {
    path: '/admin/news/view/:id',
    name: 'adminNewsDetail',
    component: function () {
      return import('../views/News/NewsDetail.vue')
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: function () {
      return import('../views/admin.vue')
    }
  },
  {
    path: '/admin/documents',
    name: 'adminDocuments',
    component: function () {
      return import('../views/Documents/DocumentsView.vue')
    }
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //   }
  // }
]

const router = new VueRouter({
  routes
})

export default router
