<template>
  <v-app
    v-if="
      $route.path != '/login' &&
      $route.path != '/admin/news' &&
      $route.path != '/admin/news/create' &&
      $route.path != '/admin/news/edit' &&
      $route.path != '/admin/news/view/:id' &&
      $route.path != '/admin/documents' &&
      $route.path != '/admin'
    "
    class="d-flex"
  >
    <v-navigation-drawer
      v-model="drawer"
      app
      dark
      color="#51788d"
      temporary
      :mini-variant="!drawer"
      mini-variant-width="0"
    >
      <v-list dense>
        <v-list-item @click="goToHome">
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Products</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            @click="handleItemClick(item.route)"
            v-for="item in items"
            :key="item.title"
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item @click="goToNews">
          <v-list-item-content>
            <v-list-item-title>News & Facilities</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="goToContact">
          <v-list-item-content>
            <v-list-item-title>Contact us</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="goToAbout">
          <v-list-item-content>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Add more menu items here as needed -->
      </v-list>
    </v-navigation-drawer>
    <v-app-bar elevation="0" dark app color="#51788d">
      <v-app-bar-nav-icon
        v-if="isMobile"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-container fluid>
        <v-row no-gutters align="center">
          <v-col
            cols="12"
            sm="4"
            md="3"
            class="d-flex justify-center justify-sm-end"
          >
            <v-img
              @click="goToHome"
              class="clickable-image"
              max-width="180"
              src="./assets/UniXcape-Logo.png"
            ></v-img>
          </v-col>

          <v-col
            cols="12"
            sm="8"
            md="9"
            class="d-flex justify-end align-center"
            v-if="!isMobile"
          >
            <v-row no-gutters class="w-100">
              <v-col
                cols="12"
                sm="3"
                style="margin-left: 900px"
                class="d-flex justify-around justify-sm-end"
              >
                <v-menu
                  open-on-hover
                  v-model="menu"
                  ref="menu"
                  :nudge-bottom="35"
                  attach
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="title-case hover-orange"
                      dark
                      text
                      v-bind="attrs"
                      v-on="on"
                      ref="menuActivator"
                    >
                      Product
                      <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>

                  <v-card class="pa-3" style="background-color: #51788d">
                    <v-list dark color="transparent">
                      <v-list-item-group>
                        <v-list-item
                          v-for="(item, index) in items"
                          :key="index"
                          @click="handleItemClick(item.route)"
                          @mouseenter="setHoveredItem(item)"
                          @mouseleave="setHoveredItem(null)"
                          :class="{ 'hovered-item': hoveredItem === item }"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              >{{ item.title }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-menu>

                <v-btn
                  @click="goToNews"
                  text
                  :class="{ 'active-route': $route.path === '/news' }"
                  class="title-case"
                >
                  News & Facilities
                </v-btn>
                <!-- <v-btn text class="title-case"> Career </v-btn> -->
                <v-btn
                  @click="goToContact"
                  :class="{ 'active-route': $route.path === '/contact' }"
                  text
                  class="title-case"
                >
                  Contact us
                </v-btn>
                <v-btn
                  @click="goToAbout"
                  :class="{ 'active-route': $route.path === '/about' }"
                  text
                  class="title-case"
                >
                  About
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main class="background">
      <router-view />
      <Footer />
    </v-main>
  </v-app>
  <v-app
    v-else-if="
      $route.path == '/login' ||
      $route.path == '/admin/news' ||
      $route.path == '/admin/news/create' ||
      $route.path == '/admin/news/edit' ||
      $route.path == '/admin/news/view/:id' ||
      $route.path == '/admin/documents' ||
      $route.path == '/admin'
    "
  >
    <v-navigation-drawer
      v-if="showNav"
      class="mx-auto"
      width="300"
      v-model="drawer"
      app
      :clipped="$vuetify.breakpoint.lgAndUp"
    >
      <v-list app>
        <v-list-item
          v-for="item in adminItems"
          :key="item.title"
          router
          :to="item.route"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="#497e98"
      dark
      v-if="showNav"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="./assets/UniXcape-Logo.png"
          width="180"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn fab color="white" small elevation="0" class="mr-3">
        <v-icon color="grey">mdi-bell-outline</v-icon>
      </v-btn>

      <v-menu v-model="onMenu" close-on-content-click offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text color="white" elevation="0" v-bind="attrs" v-on="on">
            {{ userId }}
            <v-icon color="white">{{
              onMenu ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </template>
        <v-btn
          text
          color="white"
          elevation="0"
          style="background-color: #f44336; width: 100%"
          @click="logout"
        >
          Logout
        </v-btn>
      </v-menu>
    </v-app-bar>
    <v-main style="background-color: #f8f8f8" class="open-sans">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import goTo from 'vuetify/lib/services/goto';
import axios from "axios";
// import {jwt_decode} from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Footer,
  },
  data() {
    return {
      onMenu: false,
      drawer: false,
      adminItems: [
        {
          title: "News",
          icon: "mdi-newspaper-variant-outline",
          route: "/admin/news",
        },
        {
          title: "Documents",
          icon: "mdi-clipboard-text-outline",
          route: "/admin/documents",
        },
      ],

      items: [
        {
          title: "UniXcape Commu",
          route: "/commu",
          tooltips:
            "UniXcape Commu is an iOS and Android app for voice and video calls over the internet,<br/> reducing cellular and roaming charges. It supports messaging, media sharing, group chats,<br/> and allows easy setup with pre-defined corporate contacts. The app saves battery by closing<br/> while waiting for calls.",
        },
        {
          title: "UniXcape Voice",
          route: "/voice",
          tooltips:
            "UniXcape Voice is a flexible platform for deploying new IP-based communication<br/> technologies at your organization's preferred pace. It boosts productivity, satisfaction, and customer service, offering an attractive alternative to traditional office settings.",
        },
        {
          title: "Omnichannel Contact Center",
          route: "/contact-center",
          tooltips:
            "Omnichannel Contact Center is a web-based application for multimedia communication,<br/> including voice, email, and live chat, supporting up to 5,000 extensions and 500 active agents.<br/> It offers advanced skills-based routing for mid-to-large contact centers, handles both inbound<br/> and outbound interactions, and integrates with existing CRM systems via HTTPS.",
        },
        {
          title: "UniXcape Meet",
          route: "/meet",
          tooltips:
            "UniXcape Meet is a video conferencing platform offering high-quality video and features for businesses of all sizes. It is a web application requiring no software installation, stable and reliable, and can be deployed quickly on inexpensive servers. The platform is also very user-friendly on the client side.",
        },
        {
          title: "UniXcape Voice + Commu",
          route: "/voice+commu",
          tooltips:
            "UniXcape Voice+Commu is a versatile platform that integrates IP-based communication technologies, supported on iOS and Android. It combines voice and video calls, reducing<br/>cellular and roaming charges, and offers a modern interface for enhanced productivity and customer service. This solution is ideal for maintaining connectivity and efficiency for mobile workforces, with reduced operational costs.",
        },
        {
          title: "CRM",
          route: "/crm",
          tooltips:
            "CRM is a system that helps organizations manage and analyze customer interactions and<br/>data throughout the customer lifecycle. Its primary goals are to improve customer<br/> relationships, retain customers, and drive business growth. CRM streamlines processes, <br/>optimizes workflows, and enhances customer interactions at every touchpoint.",
        },
      ],
      menu: false,
      menuWidth: "0px",
      hoveredItem: null,

      selectedItem: null, // To track selected item
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.name;
    },
    backgroundClass() {
      return this.$vuetify.breakpoint.xs ? "xs-width" : "";
    },
    showSpacer() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    isSmall() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    headerSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h5";
        case "sm":
          return "text-h3";
        case "md":
          return "text-h3";
        case "lg":
          return "text-h2";
        case "xl":
          return "text-h2";
      }
    },
    textSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size: 12px !important";
        case "sm":
          return "font-size:14px !important";
        case "md":
          return "font-size:16px !important";
        case "lg":
          return "font-size:16px !important";
        case "xl":
          return "font-size:16px !important";
      }
    },
    isDesktop() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
          navigator.userAgent
        )
      ) {
        // some code..
        console.log("mobile detected");
        return false;
      } else {
        console.log("desktop detected");
        return true;
      }
    },
    headerWidthClass() {
      if (!this.isDesktop) {
        return "xs-width";
      }
      return "";
    },
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 180px";
        case "sm":
          return "width: 400px";
        case "md":
          return "width: 400px";
        case "lg":
          return "width: 600px";
        case "xl":
          return "width: 630px";
      }
    },
    textMarginStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return;
        case "sm":
          return;
        case "md":
          return "marginLeft: 100px";
        case "lg":
          return "marginLeft: 350px";
        case "xl":
          return "marginLeft: 500px";
      }
    },

    showNav() {
      return this.$route.name != "login";
    },
    userId() {
      return this.$store.state.user.id;
    },
  },

  async created() {
    console.log("this.$route.name", this.$route.name);
    if (this.$route.name && this.$route.name.includes("admin")) {
      console.log("this.$store.state.token : ", this.$store.state.token);
      if (this.$store.state.token) {
        this.refreshToken();

        setInterval(() => {
          this.refreshToken();
        }, 300000);
      } else {
        if (this.$route.name != "Login") {
          this.$router.push({
            name: "login",
          });
        }
      }
    }
  },

  mounted() {
    // Initialize selectedItem to match the current route when component mounts
  },

  async updated() {
    console.log("this.$route.name", this.$route.name);
    if (this.$route.name && this.$route.name.includes("admin")) {
      console.log("this.$store.state.token : ", this.$store.state.token);
      if (this.$store.state.token) {
        this.refreshToken();

        setInterval(() => {
          this.refreshToken();
        }, 300000);
      } else {
        if (this.$route.name != "Login") {
          this.$router.push({
            name: "login",
          });
        }
      }
    }
  },

  methods: {
    logout() {
      console.log("go to logout page");
      localStorage.clear();
      this.$store.commit("resetInitialData");
      location.reload();
    },
    refreshToken() {
      const request = `api/users/refresh`;
      console.log("REST request : " + request);
      axios
        .get(request, {
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
        })
        .then((res) => {
          console.log("res is : ", res.data);
          const tokenInfo = jwtDecode(res.data.jwt);

          this.$store.commit("setToken", res.data.jwt);
          this.$store.commit("setExp", tokenInfo.exp);

          localStorage.setItem("token", window.btoa(res.data.jwt));
          localStorage.setItem("exp", window.btoa(tokenInfo.exp));
        })
        .catch((err) => {
          console.log("go to logout page");
          console.log(err);
          localStorage.clear();
          this.$store.commit("resetInitialData");
          location.reload();
        });
    },
    checkTimeout() {
      var now = Date.now();
      var timer = this.$store.state.user.exp - now / 1000;
      if (timer < 0) {
        console.log("time out");
        console.log("expired in :", timer);
        localStorage.clear();
        this.$store.commit("resetInitialData");
        location.reload();
      } else {
        console.log("expired in :", timer);
        const hourBeforeTimeout = (timer - 1800) * 1000;
        setTimeout(() => {
          // this.refreshToken();
        }, hourBeforeTimeout);
      }
    },
    setHoveredItem(item) {
      console.log("Hey!!!!!", item);
      this.hoveredItem = item;
    },
    updateMenuWidth() {
      this.$nextTick(() => {
        const activator = this.$refs.menuActivator.$el;
        if (activator) {
          this.menuWidth = `${activator.offsetWidth}px`;
        }
      });
    },
    goToContact() {
      this.$router.push({ path: "/contact" });
    },
    goToAbout() {
      this.$router.push({ path: "/about" });
    },
    goToHome() {
      if (this.$route.fullPath !== "/") {
        this.$router.push({ path: "/" });
      }
    },
    goToNews() {
      this.$router.push({ path: "/news" });
    },
    handleItemClick(item) {
      try {
        if (item) {
          if (this.$route.path == item) return;
          this.$router.push({ path: item });
        } else {
          console.log("Route not defined for this product");
          // Optionally, handle products without a route
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  watch: {},
};
</script>
<style scoped>
.background {
  background: url(" ./assets/background.png") center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 90%;
  background-attachment: fixed;
}

.hovered {
  background-color: orange !important;
  color: white !important;
  /* Optional: If you want to change the text color too */
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  /*
        Semi-transparent dark overlay */
  pointer-events: none;
  /* Ensures overlay doesn't interfere with interactions */
}

.hover-orange:hover {
  color: orange;
}

.clickable-image {
  cursor: pointer;
}

.active-route {
  color: orange;
}

.custom-background {
  background-color: rgba(101, 140, 161, 0.5);
}

.divider {
  background-color: #f0f0f0;
}

.vertical {
  background-color: #f0f0f0;
}

.title-case {
  text-transform: capitalize;
}

.menu-width .v-menu__content {
  width: 100%;
}

.background {
  background: url(" ./assets/background.png") center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed;
}

.hovered {
  background-color: orange !important;
  color: white !important;
  /* Optional: If you want to change the text color too */
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  /*
        Semi-transparent dark overlay */
  pointer-events: none;
  /* Ensures overlay doesn't interfere with interactions */
}

.hover-orange:hover {
  color: orange;
}

.active-list-item {
  color: orange;
  /* Change this to your desired color */
}

.clickable-image {
  cursor: pointer;
}

.active-route {
  color: orange;
}

.custom-background {
  background-color: rgba(101, 140, 161, 0.5);
}

.divider {
  background-color: #f0f0f0;
}

.vertical {
  background-color: #f0f0f0;
}

.title-case {
  text-transform: capitalize;
}

.hovered-item {
  background-color: orange;
  border-radius: 8px;
}

.v-application .primary--text {
  color: orange !important;
}

.xs-width {
  width: 120vw;
}

.selected-item {
  background-color: orange; /* Selected item effect */
}

.active-background {
}

.theme--dark.v-list-item--active::before {
  opacity: 0;
}
</style>
