<template>
  <v-container fluid :class="headerWidthClass">
    <v-row class="d-flex justify-center">
      <v-col cols="10" lg="8" class="justify-start d-flex" style="color: #f68b44; margin-top: 40px">
        <h1>UniXcape Commu</h1>
      </v-col>
    </v-row>
    <v-row class="justify-start d-flex">
      <v-col cols="12" lg="12">
        <v-divider class="custom-height" :style="dividerWidth" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6" class="justify-center text-section mt-6">
        <v-row class="d-flex" :class="isLarge ? 'justify-end' : 'justify-center'">
          <v-col cols="10" md="10" lg="10" :class="isSmall ? 'justify-center d-flex' : 'justify-start d-flex'">
            <h2 :class="headerClass">UniXcape Commu</h2>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-end' : 'justify-center'">
          <v-col cols="11" md="10" lg="10" class="justify-center d-flex">
            <p class="text-start">
              UniXcape Commu is the application supported on iOS and Android
              (iOS 13 above and Android 7 above) that provides multiple modes of
              communication consisting of Voice Call and Video Call that allows
              you to make or receive calls at anytime and anywhere without
              cellular charge (Internet connection required). This app helps to
              lower air-time minute and eliminate roaming charges. It allows you
              to send messages, media files, group chat, etc. With our
              deployment facility, users can easily setup and start using the
              application. The system administrator can pre-define corporate
              contact list and Group members for all users. In addition, The
              Application can be closed during a waiting incoming call to save
              battery consumption.
            </p>
          </v-col>
        </v-row>
        <v-row class="justify-end mt-6">
          <v-col cols="12" lg="10" class="justify-center d-flex">
            <div class="justify-start">
              <v-img :width="imageWidth" src="../assets/Download on the.png"></v-img>
            </div>
            <div class="ml-10">
              <v-img :width="imageWidth" src="../assets/Google Play.png"></v-img>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mt-6" :cols="12" :sm="12" :md="12" :lg="4" :xl="4" :xxl="4" :class="justifyCommu">
        <div class="mt-6 d-flex" :class="justifyCommu">
          <v-img width="80%" src="../assets/home1.png"></v-img>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6">
        <v-row class="d-flex" :class="justifyCommu">
          <v-col cols="8" class="d-flex" :class="justifyCommu">
            <v-img width="90%" src="../assets/commu2.png"></v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="12" :sm="12" :md="12" :lg="5" :xl="5" :xxl="5" class="text-section">
        <v-row class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start  ml-16'">
          <v-col cols="8" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <h2 class="text-start">Solution Overview</h2>
          </v-col>
        </v-row>

        <v-row class="d-flex" :class="isLarge ? 'justify-start ml-16' : 'justify-center'">
          <v-col cols="11" md="10" lg="10" :class="isSmall ? 'justify-center' : 'justify-start'">
            <p style="color: white">
              The rise of remote workers requires mobile workforces to
              communicate easily whenever they want in regardless of locations.
              Your team needs to send & received information and important calls
              and messages. A Unified Communication Solution is the simplest way
              to allow seamless communications between you team.<br /><br />
              UniXcape Communicator provides mobility solutions for the next
              generation mobile client on Smartphones. It combines SIP
              PABX-based VOIP, UniXcape Communications and Video Feature into
              ONE single application.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="8" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
        <h2 class="text-start" style="color: white">Features</h2>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col :cols="11" lg="8" class="justify-center d-flex">
        <v-row class="d-flex justify-start" :class="isLarge ? 'mt-8' : 'mt-5'">
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="200px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-card-account-phone-outline</v-icon>
                Voice / video call
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Make and receive Voice-only or Voice and Video calls on
                  3G/4G/5G or Wi-Fi connection. The quality of Voice is improved
                  by sets of Codecs designed for internet connection. The
                  application can be closed during incoming calls to save
                  battery consumption.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="200px">
              <v-card-title class="feature-text-title"><v-icon class="mr-3"
                  style="color: white">mdi-dns-outline</v-icon>
                Legacy
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Unixcape Communicator server can connect to existing PBX with
                  a one-number service feature that depends on the existing PBX
                  feature. It utilizes a voice gateway or PSTN using SIP
                  standards. This allows for smooth communication between office
                  users and mobile users who work remotely.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="200px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-cellphone-cog</v-icon>Seamless Setup
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Users simply enter the Company code or scan the QR code. The
                  system will prepare all necessary configurations and upload
                  the application. Users can start using the application within
                  seconds after installation. This can significantly reduce the
                  need for IT support to assist users with setting up
                  complicated configurations.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="200px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-application-outline</v-icon>
                Web
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Integrate with web applications and provide APIs to optimize
                  click-to-call, click-to-chat, and notifications.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="200px">
              <v-card-title class="feature-text-title"><v-icon class="mr-3"
                  style="color: white">mdi-message-outline</v-icon>
                Chat
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Send and receive messages between users or within groups of
                  users, upload photos, or save them to your phone. Group chats
                  are defined by the system administrator, eliminating the need
                  to add users one by one. You can ensure that only authorized
                  users can see your messages.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-start d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="200px">
              <v-card-title class="feature-text-title"><v-icon class="mr-3"
                  style="color: white">mdi-shield-plus-outline</v-icon>
                Security
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Signalling and media streams are encrypted throughout the
                  communication process to prevent unauthorized manipulation by
                  third parties. Your messages are delivered safely and only to
                  your designated recipients.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%" min-height="200px">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-account-group</v-icon>
                Corporate Extension Call
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Apart from personal contacts on your smartphone, a pre-defined
                  Corporate contact list is prepared and delivered to every
                  user. Users can call anyone in the company immediately from
                  the list, without the need to waste time adding contacts one
                  by one.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <h1 style="color: #f0f0f0" class="text-center">
          Connect to see More Information
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <h4 style="color: #f0f0f0">UniXcape Commu Information PDF File</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <v-icon style="color: #f0f0f0; font-size: 6rem">mdi-information-variant-circle</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <v-btn @click="clickToDownload" outlined color="#f68b44" class="title-case">Download File</v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      path: "",
    };
  },
  computed: {
    isMobile() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      }
      return false;
    },
    dividerThickness() {
      if (this.$vuetify.breakpoint.xs) {
        return 2;
      } else if (this.$vuetify.breakpoint.sm) {
        return 4;
      } else if (this.$vuetify.breakpoint.md) {
        return 6;
      } else if (this.$vuetify.breakpoint.lg) {
        return 8;
      } else if (this.$vuetify.breakpoint.xl) {
        return 10;
      } else {
        return 6; // Default thickness
      }
    },
    dividerWidth() {
      const width = window.innerWidth;
      console.log("width : ", width);
      const is2K = width >= 2048 && width < 3840;
      const is4K = width >= 3840;

      if (is2K) {
        return "width: 27%";
      }

      if (is4K) {
        return "width: 27%";
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 70%";
        case "sm":
          return "width: 42%";
        case "md":
          return "width: 34%";
        case "lg":
          return "width: 35%";
        case "xl":
          return "width: 30%";
      }
    },
    headerWidthClass() {
      if (this.$vuetify.breakpoint.xs) {
        return "xs-width";
      }
      return "";
    },
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "120px";
        case "sm":
          return "120px";
        case "md":
          return "200px";
        case "lg":
          return "200px";
        case "xl":
          return "200px";
      }
    },
    justifyCommu() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "justify-center ";
        case "sm":
          return "justify-center ";
        case "md":
          return "justify-center ";
        case "lg":
          return "justify-end ";
        case "xl":
          return "justify-end ";
      }
    },
    headerClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "";
        case "sm":
          return "";
        case "md":
          return "";
        case "lg":
          return "";
        case "xl":
          return "";
      }
    },
    isSmall() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    isLarge() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    clickToDownload() {
      const file = this.path.find((doc) => doc._id === "UXC-Commu");
      if (file) {
        this.downloadFile(file.documentPath);
      } else {
        console.log("File not found");
      }
    },
    downloadFile(path) {
      const url = `${process.env.BASE_URL}${path}`;
      const filename = path.split("/").pop(); // Extract the filename from the path
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set only the filename in the download attribute
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    pathPdf(data) {
      for (const item of data) {
        const url = item.documentPath;
        const basePath = "/html/";
        const path = url.split(basePath)[1];
        item.documentPath = path;
        console.log("path", path);
      }
    },
    async initialize() {
      const request = "api/documents";

      await axios
        .get(request, {})
        .then((res) => {
          console.log("response : ", res);
          this.path = res.data.rows;
          this.pathPdf(this.path);
          console.log("lol", this.path);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.divider-line {
  background-color: #f68b44;
  margin-right: 140px;
}

.divider-center {
  background-color: #f68b44;
  margin-left: 300px;
  margin-right: 300px;
}

.title-section {
  text-align: center;
  color: #f68b44;
  margin-top: 40px;
}

.short-divider {
  margin: 20px auto;
  width: 20%;
}

.text-section {
  text-align: left;
  color: #f0f0f0;
}

.feature-title {
  text-align: center;
  color: #f0f0f0;
  margin-top: 40px;
}

.feature-card {
  background-color: #000000;
  color: #f0f0f0;
  padding: 20px;
  margin: 10px;
}

.feature-icon {
  font-size: 6rem;
  color: #f68b44;
}

.feature-text-title {
  color: #f0f0f0;
  font-size: 20px;
  margin-bottom: 10px;
}

.feature-text {
  color: #f0f0f0;
}

.divider-center {
  margin: 40px auto;
  width: 80%;
}

.wide-divider,
.wide-divider-bottom {
  margin: 60px auto;
  width: 80%;
}

.info-icon {
  font-size: 6rem;
  color: #f0f0f0;
}

.title-case {
  text-transform: capitalize;
}

.custom-height {
  height: 100px;
}

.xs-width {
  width: 120vw;
}
</style>
