<template>
  <v-container fluid :class="headerWidthClass">
    <v-row class="d-flex justify-center">
      <v-col cols="10" lg="8" class="justify-start d-flex pb-0" style="color: #f68b44; margin-top: 40px">
        <h1 style="text-wrap: auto">Omnichannel</h1>
      </v-col>

      <v-col cols="10" lg="8" class="justify-start d-flex pt-0" style="color: #f68b44">
        <h1 style="text-wrap: auto">Contact Center</h1>
      </v-col>
    </v-row>

    <v-row class="justify-start d-flex">
      <v-col cols="12" lg="12">
        <v-divider :style="dividerWidth" color="#f68b44"></v-divider>
      </v-col>
    </v-row>

    <v-row class="justify-center d-flex">
      <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6" class="justify-center text-section mt-6">
        <v-row class="d-flex" :class="isLarge ? 'justify-end' : 'justify-center'">
          <v-col cols="10" md="10" lg="10" :class="isSmall ? 'justify-center d-flex' : 'justify-start d-flex'">
            <h2 :class="headerClass">Omnichannel Contact Center</h2>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-end' : 'justify-center'">
          <v-col cols="11" md="10" lg="10" class="justify-center d-flex">
            <p class="text-start">
              Omnichannel Contact Center is Web base application for working on
              anywhere, an integrated multimedia solution as Voice, Email, Live
              chat and more featuring advanced mid-to-large contact center with
              up to 5,000 Extensions. Whether inbound or outbound interactions,
              single-site or multi-site, or closely integrated with your
              existing CRM systems, Contact Center and Fax Server, IVR
              Enterprise provides robust capabilities for your business.
              Omnichannel contact center solution featuring advanced
              skills-based routing for the mid-to-large contact center with up
              to 500 active agents. Whether inbound or outbound interactions,
              single-site or multi-site, or closely integrated with your
              existing CRM systems via https protocol , Omnichannel contact
              center Enterprise provides robust contact center capabilities.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mt-6 text-center" :cols="12" :sm="12" :md="12" :lg="4" :xl="4" :xxl="4" :class="justifyCommu">
        <div class="mt-6 d-flex justify-center">
          <v-img contain max-width="70%" max-height="100%" src="../assets/home3.png"></v-img>
        </div>
      </v-col>
    </v-row>

    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>

    <v-row class="justify-center d-flex">
      <v-col :cols="12" :sm="12" :md="12" :lg="5" :xl="5" :xxl="5">
        <v-row class="d-flex" :class="justifyCommu">
          <v-col cols="12" class="d-flex" :class="justifyCommu">
            <div class="d-flex" :class="justifyCommu" :style="isMobile ? 'width:200px; ' : 'width:550px'">
              <v-img class="mt-10" width="100%" src="../assets/contact architecture.png"></v-img>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col :cols="12" :sm="12" :md="12" :lg="5" :xl="5" :xxl="5" class="justify-center text-section mt-6">
        <v-row class="d-flex" :class="isLarge ? 'justify-end' : 'justify-center'">
          <v-col cols="10" md="10" lg="10" :class="isSmall ? 'justify-center d-flex' : 'justify-start d-flex'">
            <h2 :class="headerClass">Architecture</h2>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-end' : 'justify-center'">
          <v-col cols="11" md="10" lg="10" class="justify-center d-flex">
            <p class="text-start">
              Omnichannel Contact Center has a modular design. This design
              allows it to be extended without breaking functionality or
              requiring massive recoding efforts. Each module provides specific
              functionality often commands that are related are grouped into one
              module. You will need to enable the modules that you desire, based
              on their function.<br /><br />

              Ready API for Integrating with CRM via Post or Get message
              protocol and flexible to special customization over on standard
              API.<br /><br />

              CTI allows a contact center or organization to gather information
              about the caller as a means of directing the inquiry to the
              appropriate agent. CTI can transfer relevant information about the
              individual customer and the IVR dialog from the IVR to the agent
              desktop using a screen-pop, making for a more effective and
              efficient service.<br /><br />

              Administration GUI is a convenient interface for the
              administration of contact center users, user profiles, groups or
              skills, resources and Greeting. By simply pointing and clicking,
              managers can assign highly flexible, permission-based profiles to
              users based on their different roles and functions in the contact
              center.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>

    <v-row class="justify-center d-flex">
      <v-col :cols="12" :sm="12" :md="12" :lg="5" :xl="5" :xxl="5" class="text-section">
        <v-row class="d-flex" :class="isLarge ? 'justify-end' : 'justify-center'">
          <v-col cols="10" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <h2 class="text-start">Solution Overview</h2>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="isLarge ? 'justify-end' : 'justify-center'">
          <v-col cols="11" md="10" lg="10" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
            <p class="text-start">
              Many organizations and endeavors require efficient operational
              supervision and coordination. All have in common the need for the
              right information to be brought together so that the right people
              can make fast, accurate decisions and track the effect of those
              decisions. Omnichannel Contact Center is a software solution that
              is designed to facilitate effective supervision and coordination
              of operations.<br /><br />

              Authorities face common challenges in their core systems and in
              making improvements to systems that are interconnected.
              Authorities that are forward-looking want to use the improvements
              in efficiency and effectiveness of smarter core systems. They
              adopt new ways of thinking about and using these systems. The
              application of advanced information technology can help
              authorities better understand, predict, and intelligently respond
              to patterns of behavior and events.
            </p>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="d-flex mt-6 justify-center" :cols="12" :sm="6" :md="6" :lg="5" :xl="5" :xxl="5">
        <div class="d-flex justify-center" :class="justifyCommu" :style="isMobile ? 'width:200px; ' : 'width:450px'">
          <v-img src="../assets/contact overview.png"></v-img>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="9">
        <v-divider class="my-6" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="8" class="d-flex" :class="isSmall ? 'justify-center' : 'justify-start'">
        <h2 class="text-start" style="color: white">Features</h2>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col :cols="11" lg="8" class="justify-center d-flex">
        <v-row class="d-flex justify-start" :class="isLarge ? 'mt-8' : 'mt-5'">
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-cellphone-arrow-down</v-icon>
                Mobility Agent Desktop with Softphone
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  This feature allows employees to work from anywhere using a
                  softphone on their desktop, enhancing flexibility and
                  productivity for remote work.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-card-account-phone-outline</v-icon>Task Priority
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Allows prioritization of tasks based on urgency or importance,
                  ensuring that critical issues are addressed promptly and
                  efficiently.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-lightbulb-on-10</v-icon>
                Skill-Based Routing
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Routes calls to the most appropriate agent based on their
                  skill set, improving the chances of first-call resolution and
                  enhancing customer satisfaction.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col class="justify-end d-flex" :cols="12" :sm="12" :md="6" :lg="6" :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="ma-3" style="color: white">mdi-lightbulb-on-10</v-icon>Skill-Based Routing
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Routes calls to the most appropriate agent based on their skill
                  set, improving the chances of first-call resolution and enhancing
                  customer satisfaction.
                </p>
              </v-card-text>
            </v-card>
          </v-col> -->
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-account-multiple-plus-outline</v-icon>
                Queue Announcement
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Queue announcements reduce customer frustration by providing
                  information about wait times, making customers feel attended
                  to and enhancing their experience.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-ip-outline</v-icon>
                SIP IP PBX High Availability
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Having a high availability PBX system ensures uninterrupted
                  call handling and system stability, increasing the reliability
                  of communication services.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-clipboard-text-outline</v-icon>
                Real Time Task Status
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Real-time task status allows managers and employees to track
                  tasks and make timely adjustments, improving efficiency and
                  time management.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-folder-swap-outline</v-icon>
                CRM API Integration
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Integration with CRM systems facilitates quick management of
                  customer information and interaction history, ensuring smooth
                  customer service operations.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-account-voice</v-icon>Real Time Task Status
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Recording voice and video calls helps in quality assurance,
                  employee training, and serves as evidence in case of disputes.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-auto-mode</v-icon>
                Auto Attendant / IVR
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Auto attendants and IVR systems reduce the workload on staff
                  by allowing customers to access information or services
                  quickly and easily.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-table-large</v-icon>
                Customize Report
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Customizable reports allow businesses to analyze data and
                  improve processes efficiently, tailored to the specific needs
                  of the organization.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-message-badge-outline</v-icon>
                Live Chat Channel
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Offers an alternative communication channel for customers who
                  prefer text-based interactions, expanding the reach and
                  flexibility of customer support.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="justify-end d-flex" :class="isMobile ? 'px-0 pb-0' : ''" :cols="12" :sm="12" :md="6" :lg="6"
            :xl="6" :xxl="6">
            <v-card style="background-color: black; border-radius: 14px" width="100%">
              <v-card-title class="feature-text-title">
                <v-icon class="mr-3" style="color: white">mdi-clipboard-text-outline</v-icon>
                Customize Report
              </v-card-title>
              <v-card-text>
                <p style="color: white">
                  Ensures that service performance meets predefined standards,
                  enhancing accountability and customer satisfaction by clearly
                  defining response and resolution times.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <h1 style="color: #f0f0f0" class="text-center">
          Connect to see More Information
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <h4 style="color: #f0f0f0">
          Omnichannel Contact Center Information PDF File
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <v-icon style="color: #f0f0f0; font-size: 6rem">mdi-information-variant-circle</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-center d-flex">
        <v-btn @click="clickToDownload" outlined color="#f68b44" class="title-case">Download File</v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  computed: {
    isMobile() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      }
      return false;
    },
    dividerWidth() {
      const width = window.innerWidth;
      console.log("width : ", width);
      const fullHd = width >= 1920 && width < 2048;
      const is2K = width >= 2048 && width < 3840;
      const is4K = width >= 3840;

      if (is2K) {
        return "width: 27%";
      }

      if (is4K) {
        return "width: 27%";
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 80%";
        case "sm":
          return "width: 73%";
        case "md":
          return "width: 57%";
        case "lg":
          return "width: 47%";
        case "xl":
          return "width: 39%";
      }
    },
    isDesktop() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
          navigator.userAgent
        )
      ) {
        // some code..
        console.log("mobile detected");
        return false;
      } else {
        console.log("desktop detected");
        return true;
      }
    },
    headerWidthClass() {
      if (this.isDesktop) {
        return "xs-width";
      }
      return "";
    },
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100px";
        case "sm":
          return "200px";
        case "md":
          return "200px";
        case "lg":
          return "200px";
        case "xl":
          return "200px";
      }
    },
    justifyCommu() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "justify-center ";
        case "sm":
          return "justify-center ";
        case "md":
          return "justify-center ";
        case "lg":
          return "justify-end ";
        case "xl":
          return "justify-end ";
      }
    },
    headerClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "";
        case "sm":
          return "";
        case "md":
          return "";
        case "lg":
          return "";
        case "xl":
          return "";
      }
    },
    isSmall() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    isLarge() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    clickToDownload() {
      const file = this.path.find((doc) => doc._id === "UXC-Omni");
      if (file) {
        this.downloadFile(file.documentPath);
      } else {
        console.log("File not found");
      }
    },
    downloadFile(path) {
      const url = `${process.env.BASE_URL}${path}`;
      const filename = path.split("/").pop(); // Extract the filename from the path
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set only the filename in the download attribute
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    pathPdf(data) {
      for (const item of data) {
        const url = item.documentPath;
        const basePath = "/html/";
        const path = url.split(basePath)[1];
        item.documentPath = path;
        console.log("path", path);
      }
    },
    async initialize() {
      const request = "api/documents";

      await axios
        .get(request, {})
        .then((res) => {
          console.log("response : ", res);
          this.path = res.data.rows;
          this.pathPdf(this.path);
          console.log("lol", this.path);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.divider-di {
  background-color: #f68b44;
  margin-right: 1435px;
}

.divider-center {
  background-color: #f68b44;
  margin-left: 300px;
  margin-right: 300px;
}

.xs-width {
  width: 120vw;
}
</style>
