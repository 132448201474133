<template>
  <v-container fluid class="ma-0" :class="headerWidthClass">
    <v-row class="d-flex justify-center">
      <v-col cols="10" lg="8" class="justify-start d-flex header-title">
        <h1>Contact Us</h1>
      </v-col>
    </v-row>
    <v-row class="justify-start d-flex pa-0">
      <v-col cols="12" lg="12">
        <v-divider :style="dividerStyle" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
    <v-row class="justify-center ma-0">
      <v-col cols="12" md="10" lg="8">
        <v-row class="d-flex " :class="isSmall ? 'justify-center d-flex' : 'justify-start d-flex'">
          <v-col v-for="(location, index) in locations" :key="index" cols="12" xs="12" sm="6" md="4" lg="4" xl="4"
            xxl="4" class="mb-5">
            <div :class="positionClass" class="location-title">
              <h2>{{ location.title }}</h2>
            </div>
            <div :class="positionClass" class="location-content">
              <span style="width: 100vw" :style="isMobile ? 'text-align: center' : ''" v-html="location.content">
              </span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col cols="11" lg="9">
        <v-divider class="my-10" color="#f68b44"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      locations: [
        {
          title: "Thailand",
          content: `TGS Enterprise Networks Ltd<br />
            Charn Issara Tower II, 20th Floor<br />
            2922/252-258 New Petchburi Road,<br />
            Bangkapi<br />
            Huaykwang, Bangkok, 10310<br /><br />
            Tel. +66 2 715 9222<br />
            Fax. +66 2 715 9201<br />
            Email service.th@tgs-enterprise.com`,
        },
        {
          title: "Malaysia",
          content: `TGS Enterprise Networks Sdn Bhd Suite 2-55 & 2-56, 2nd Floor, Block 4812,<br />
            CBD Perdana 2, Jalan Perdana, Cyber 12, 63000<br />
            Cyberjaya Selangor<br /><br />
            Tel. +60 3 8800 5555<br />
            Fax. +60 3 8800 5500<br />
            Email tgscic@tgs-enterprise.com`,
        },
        {
          title: "Singapore",
          content: `TGS Enterprise Networks<br />
            Graha Tunas, Jl. Warung Jati Barat<br />
            No.63, Jakarta Selatan 12740,<br />
            Indonesia<br /><br />
            Tel. +62 21 798 8600<br />
            Fax. +62 21 7901620<br />
            Email support@tgs-enterprise.com`,
        },
        {
          title: "Indonesia",
          content: `TGS Enterprise Networks<br />
            Graha Tunas, Jl. Warung Jati Barat No.63,<br />
            Jakarta Selatan 12740, Indonesia<br /><br />
            Tel. +62 21 798 8600<br />
            Fax. +62 21 7901620<br />
            Email support@tgs-enterprise.com`,
        },
        {
          title: "Brunei",
          content: `No 7, 2nd floor, Muhibbah Complex<br />
            Kg Menglait BE3919<br />
            Brunei Darussalam<br /><br />
            Tel. +673 242 1668<br />
            Tel. +673 243 1668<br />
            Email service@tgs-enterprise.com`,
        },
        {
          title: "Philippines",
          content: `GS Enterprise Networks Philippines<br />
            ETSI Compound, Mercedes Business Park,<br />
            Mercedes Avenue Cainta Rizal<br /><br />
            Tel. +63 (2) 643 5555 Loc 288<br />
            Email service@tgs-enterprise.com`,
        },
        {
          title: "Australia",
          content: `TGS Enterprise Networks Pty Ltd<br />
            19 Farrington Road<br />
            Leeming Western Australia 6149<br /><br />
            Tel. +61 (8) 6150 7271<br />
            Fax. +61 (8) 6161 7332`,
        },
        {
          title: "Myanmar",
          content: `No.55/C, University Avenue Road,<br />
            Bahan Township, Yangon<br />
            Myanmar<br /><br />
            Tel. +951 52 5657<br />
            Email service@tgs-enterprise.com`,
        },
      ],
    };
  },
  computed: {
    isMobile() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      }
      return false;
    },
    dividerStyle() {
      const width = window.innerWidth;
      const is2K = width >= 2048 && width < 3840;
      const is4K = width >= 3840;

      if (is2K) return "width: 23%";
      if (is4K) return "width: 10%";

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 53%";
        case "sm":
          return "width: 21%";
        case "md":
          return "width: 24%";
        case "lg":
          return "width: 27%";
        case "xl":
          return "width: 25%";
      }
    },
    headerWidthClass() {
      return this.$vuetify.breakpoint.xs ? "xs-width" : "";
    },
    positionClass() {
      return this.$vuetify.breakpoint.name === "xs"
        ? "d-flex justify-center"
        : "d-flex justify-start";
    },
    isSmall() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
  },
};
</script>

<style>
.header-title {
  color: #f68b44;
  margin-top: 40px;
}

.location-title {
  color: #f68b44;
  margin-top: 40px;
}

.location-content {
  color: #f0f0f0;
  margin-top: 20px;
}

.xs-width {
  width: 120vw;
}
</style>
