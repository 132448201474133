import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      id: null,
      // role: "blank",
      exp: ""
    },
    token: null,
    refreshtoken: "",
  },
  getters: {
  },
  mutations: {
    setExp(state, data) {
      state.user.exp = data
    },
    setInitialData(state, data) {
      state.token = data.token
      // state.refreshtoken = data.refreshtoken
      state.user = data.user
    },
    setToken(state, data) {
      state.token = data
    },
    resetInitialData(state) {
      state.user = null
      state.token = null
    },
    initialiseStore(state) {
      if (localStorage.getItem('token')) {
        console.log("initialiseStore");
        console.log(state);
        state.token = window.atob(localStorage.getItem('token'));
        state.user.id = window.atob(localStorage.getItem('id'));
        // state.user.role = window.atob(localStorage.getItem('role'));
        state.user.exp = window.atob(localStorage.getItem('exp'));
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
