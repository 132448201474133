<template>
    <div>

        <v-row class="justify-center">
            <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6">
                <v-row class="d-flex" :class="justifyCommu">
                    <v-col cols="12" md="10" class="d-flex" :class="justifyCommu" v-for="item in filteredNews"
                        :key="item.title">
                        <v-img width="90%" :src="showImage(item.coverImage)"></v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="justify-center">
            <v-col :cols="12" :sm="12" :md="12" :lg="6" :xl="6" :xxl="6" v-for="item in filteredNews" :key="item.title">
                <!-- Title Row -->
                <v-row class="d-flex" :class="isSmall ? 'justify-center' : 'justify-center'">
                    <v-col cols="12" class="d-flex justify-center" style="color: #f0f0f0">
                        <h2 class="text-start">{{ item.title }}</h2>
                    </v-col>
                </v-row>

                <!-- Description Row -->
                <v-container>
                    <v-row class="d-flex" :class="isLarge ? 'justify-center' : 'justify-center'">
                        <v-col cols="12" class="d-flex align-center" style="color: #f0f0f0">
                            <div class="text-center w-100">
                                <p class="m-0">{{ item.description }}</p>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="justify-center d-flex">
            <v-col cols="9">
                <v-divider class="my-10" color="#f68b44"></v-divider>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    props: ['title'],
    data() {
        return {
            news: [],
            newsItem: {},
            filteredNews: []
        }
    },
    created() {
        this.initialize();
    },
    computed: {
        dividerWidth() {
            const width = window.innerWidth;
            console.log("width : ", width);
            const fullHd = width >= 1920 && width < 2048
            const is2K = width >= 2048 && width < 3840;
            const is4K = width >= 3840;


            if (is2K) {
                return "width: 27%";
            }

            if (is4K) {
                return "width: 27%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "width: 100%";
                case "sm":
                    return "width: 57%";
                case "md":
                    return "width: 49%";
                case "lg":
                    return "width: 42%";
                case "xl":
                    return "width: 36%";
            }
        },
        imageWidth() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100px";
                case "sm":
                    return "200px";
                case "md":
                    return "200px";
                case "lg":
                    return "200px";
                case "xl":
                    return "200px";
            }
        },
        justifyCommu() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "justify-center ";
                case "sm":
                    return "justify-center ";
                case "md":
                    return "justify-center ";
                case "lg":
                    return "justify-center ";
                case "xl":
                    return "justify-center ";
            }
        },
        headerClass() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "";
                case "sm":
                    return "";
                case "md":
                    return "";
                case "lg":
                    return "";
                case "xl":
                    return "";
            }
        },
        isSmall() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return true;
                case "sm":
                    return false;
                case "md":
                    return true;
                case "lg":
                    return false;
                case "xl":
                    return false;
            }
        },
        isLarge() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return false;
                case "sm":
                    return false;
                case "md":
                    return false;
                case "lg":
                    return true;
                case "xl":
                    return true;
            }
        },
        isDesktop() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
                    navigator.userAgent
                )
            ) {
                // some code..
                console.log("mobile detected");
                return false;
            } else {
                console.log("desktop detected");
                return true;
            }
        },
        colClass() {
            if (!this.isDesktop) {
                if (this.$vuetify.breakpoint.xs) {
                    return "px-0 pb-0";
                }
            }
            return "";
        },
    },
    mounted() {
        this.newsItem = this.getNewsItem(this.title);
        console.log("title", this.title)
    },
    methods: {
        async initialize() {
            const request = "api/news/publish";

            await axios
                .get(request, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    console.log("response : ", res);
                    this.news = res.data
                    this.total = res.data.total;
                    this.pathImage(this.news)
                    this.filteredNews = this.news.filter(item => item.title === this.title);
                    console.log("yyyy", this.filteredNews)

                })
                .catch((err) => {
                    console.log(err);
                });
        },
        pathImage(data) {
            for (const item of data) {
                const url = item.coverImage
                const basePath = "/html/";
                const path = url.split(basePath)[1];
                item.coverImage = path
                console.log("path", path)
            }
        },
        getNewsItem(title) {
            return this.news.find(item => item.title === title);
        },
        showImage(image) {
            return image ? `${process.env.BASE_URL}${image}` : 'default-image.jpg';
        },
    },
}
</script>

<style>
.line-divider {
    background-color: #f68b44;
    margin-right: 1310px;
}
</style>