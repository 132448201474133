import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{attrs:{"cols":12,"sm":12,"md":12,"lg":6,"xl":6,"xxl":6}},[_c(VRow,{staticClass:"d-flex",class:_vm.justifyCommu},_vm._l((_vm.filteredNews),function(item){return _c(VCol,{key:item.title,staticClass:"d-flex",class:_vm.justifyCommu,attrs:{"cols":"12","md":"10"}},[_c(VImg,{attrs:{"width":"90%","src":_vm.showImage(item.coverImage)}})],1)}),1)],1)],1),_c(VRow,{staticClass:"justify-center"},_vm._l((_vm.filteredNews),function(item){return _c(VCol,{key:item.title,attrs:{"cols":12,"sm":12,"md":12,"lg":6,"xl":6,"xxl":6}},[_c(VRow,{staticClass:"d-flex",class:_vm.isSmall ? 'justify-center' : 'justify-center'},[_c(VCol,{staticClass:"d-flex justify-center",staticStyle:{"color":"#f0f0f0"},attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-start"},[_vm._v(_vm._s(item.title))])])],1),_c(VContainer,[_c(VRow,{staticClass:"d-flex",class:_vm.isLarge ? 'justify-center' : 'justify-center'},[_c(VCol,{staticClass:"d-flex align-center",staticStyle:{"color":"#f0f0f0"},attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center w-100"},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(item.description))])])])],1)],1)],1)}),1),_c(VRow,{staticClass:"justify-center d-flex"},[_c(VCol,{attrs:{"cols":"9"}},[_c(VDivider,{staticClass:"my-10",attrs:{"color":"#f68b44"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }